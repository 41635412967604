//const BACKEND_ADRESS = 'http://localhost:8000/graphql'
//const BACKEND_ADRESS = 'http://graphqltuorial-env.eba-bmibwfqm.us-east-2.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'http://limabackend.eba-bmibwfqm.us-east-2.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'http://limanticbackend-env.eba-ktayy22j.eu-north-1.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'https://backend.app.limantic.com/graphql'

// Umgebungsvariablen
// Diese Funktionieren nur mit dem consolen aufruf: npm start .env
// diese werden in der Entwicklungsumgebung im Projekt im file .env gepflegt
// Beschrieben auf: https://create-react-app.dev/docs/adding-custom-environment-variables/
const BACKEND_ADRESS = process.env.REACT_APP_BACKEND_ADRESS; 

// Get all Items
export const GetAllLinks = (setLinkArray,token) => {

    // Query
    const requestBody = {
        query: `
                query {
                    links{
                        _id
                        fromId
                        toId
                        name
                        description
                        date   
                        type
                        creator {
                            email
                        }                   
                    }
                }
            `
    }

    // Communication with Backend
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        const links = resData.data.links;
        setLinkArray(links)
        //console.log(links)
    }).catch(err => {
        //console.log(err)
    });
}

///////////////////////
// Delete Link by ID //
export const DeleteLink = (linkId, setLinkArray, linkArray, token) => {


    // Query
    const requestBody = {
        query: `
                mutation DeleteLink ($id: ID!){
                    deleteLink(linkId:$id){
                        name
                        _id
                    }
                }
            `,
        variables: {
            id: linkId
        }
    }


    // Communication with Backend    
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        // Update Links     
        //console.log("### Delete Link: "+ linkId)
        const newLinkArray = linkArray.filter(link => {      
            return link._id !== linkId;
        })   
        setLinkArray(newLinkArray)
    }).catch(err => {
        //console.log(err)
    });
}

///////////////////////
// Update Link by ID //
export const UpdateLink = (linkItem, token) => {

    
    if(linkItem.abbreviation=="" || !linkItem.abbreviation){linkItem.abbreviation = "abbreviation"}
  
    // Queries Login or CreateUser
    const requestBody = {
        query: `
                    mutation UpdateLink($linkId: ID!, $fromId: ID!, $toId: ID!, $name: String!, $description: String!, $abbreviation: String!, $date: String! $type: String!){
                        updateLink(linkId: $linkId  linkInput: {fromId: $fromId, toId: $toId, name: $name, description: $description, abbreviation: $abbreviation, date: $date, type: $type }) {
                            _id
                            name
                            abbreviation
                            description
                            date
                            type
                            creator{
                                _id
                            }
                        }
                    }
                `,
        variables: {
            fromId: linkItem.fromId,
            toId: linkItem.toId,
            name: linkItem.name,
            abbreviation: linkItem.abbreviation,
            date: linkItem.date,
            description: linkItem.description,
            linkId: linkItem._id,
            type: linkItem.type,
        }
    }


    // Functuion to update
    const handelResData = (resData) => {
        //setLinkItem(linkItem)
    }

    // Communicate with Backend
    Communication(requestBody, handelResData, token);
}

/////////////////
// Create Link // 
export const CreateLink = (linkItem, setLinkArray, token) => {

    if(linkItem.abbreviation=="" || !linkItem.abbreviation){linkItem.abbreviation = "abbreviation"}

    // Queries Login or CreateUser
    const requestBody = {
        query: `
                mutation CreateLink($fromId: ID!, $toId: ID!, $name: String!, $description: String!, $abbreviation: String!, $date: String!, $type: String!){
                    createLink(linkInput: {fromId: $fromId, toId: $toId, name: $name, description: $description, abbreviation: $abbreviation, date: $date, type: $type }) {
                        _id
                        name
                        abbreviation
                        description
                        type
                        fromId
                        toId
                        creator{
                            _id
                            email
                        }
                    }
                }
            `,
        variables: {
            fromId: linkItem.fromId,
            toId: linkItem.toId,
            name: linkItem.name,
            abbreviation: linkItem.abbreviation,
            date: linkItem.date,
            description: linkItem.description,
            type: linkItem.type,
        }
    }

    // Functuion to Add new Link to LinkData
    const handelResData = (resData) => {
        if (resData.data.createLink) {
            //console.log("111 Set new Link to list");
            //console.log(resData.data.createLink);
            setLinkArray(prevState => {
                return [
                    ...prevState,
                    {
                        ...resData.data.createLink
                    }
                ];
            });
        }
    }

    // Communicate with Backend
    Communication(requestBody, handelResData, token);

}

///////////////////
// Communication //
export const Communication = (requestBody, handelResData, token) => {

    // Communication with Backend
    //console.log(JSON.stringify(requestBody))
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        handelResData(resData);
    }).catch(err => {
        //console.log(err)
        return err;
    });
}
