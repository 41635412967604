import {Fab, makeStyles } from '@material-ui/core';
import React from 'react';
import { useReactFlow, useStoreApi
} from 'react-flow-renderer';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';

// Documentation under:
// https://reactflow.dev/docs/api/react-flow-instance/


const useStyles = makeStyles((theme) => ({
  fabOrientation: {
      [theme.breakpoints.down("sm")]: {
          display: "none",
      }
  }
}));

export const FLowChartToolBar = () => {
  const classes = useStyles();
  const store = useStoreApi();
  //const { zoomIn, zoomOut, setCenter, fitView } = useZoomPanHelper();
  const { setCenter, zoomIn, zoomOut, fitView} = useReactFlow();

 

  const focusNode = () => {
    const { nodeInternals } = store.getState();
    const nodes = Array.from(nodeInternals).map(([, node]) => node);

    if (nodes.length > 0) {
      const node = nodes[0];

      const x = node.position.x + node.width / 2;
      const y = node.position.y + node.height / 2;
      const zoom = 1.85;

      setCenter(x, y, { zoom, duration: 1000 });
    }
  };

  // Add a new Node
 /* let nodeId = 0;
  const id = `${++nodeId}`;
   const newNode = {
    id,
    position: {
      x: Math.random() * 500,
      y: Math.random() * 500,
    },
    data: {
      label: `Node ${id}`,
    },
  }; */

  return (
    <> 
      <Fab className={classes.fabOrientation} onClick={() => fitView()} size="small" color="inherit" aria-label="edit">
        <ZoomOutMapIcon />
      </Fab>
      <Fab className={classes.fabOrientation} onClick={() => focusNode()} size="small" color="inherit" aria-label="edit">
        <FilterCenterFocusIcon />
      </Fab>
      <Fab className={classes.fabOrientation} onClick={() => zoomIn()} size="small" color="inherit" aria-label="edit">
        <ZoomInIcon />
      </Fab>
      <Fab className={classes.fabOrientation} onClick={() => zoomOut()} size="small" color="inherit" aria-label="edit">
        <ZoomOutIcon />
      </Fab>     
      {/* <Fab className={classes.fabOrientation} onClick={() => addNodes(newNode)} size="small" color="inherit" aria-label="edit">
      </Fab>   */}  
    </>
  );
};