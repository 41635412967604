//const BACKEND_ADRESS = 'http://localhost:8000/graphql'
//const BACKEND_ADRESS = 'http://graphqltuorial-env.eba-bmibwfqm.us-east-2.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'http://limabackend.eba-bmibwfqm.us-east-2.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'http://limanticbackend-env.eba-ktayy22j.eu-north-1.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'https://backend.app.limantic.com/graphql'

import { loadInitalSettings } from "../settings/initialSettings";

// Umgebungsvariablen
// Diese Funktionieren nur mit dem consolen aufruf: npm start .env 
// diese werden in der Entwicklungsumgebung im Projekt im file .env gepflegt
// Beschrieben auf: https://create-react-app.dev/docs/adding-custom-environment-variables/
const BACKEND_ADRESS = process.env.REACT_APP_BACKEND_ADRESS;



// Get all Items
export const GetAllSettings = (setSettingArray, token) => {

    //console.log("####### ")
    // Query
    const requestBody = {
        query: `
                query {
                    settings{
                        _id
                        name
                        description
                        parameters                 
                    }
                }
            `
    }

    // Communication with Backend
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        //console.log(resData.data.settings)
        const Settings = resData.data.settings;
        setSettingArray(Settings)
        //console.log("####### Settings")
        //console.log(Settings) 
        if(Settings.length===0){
            //console.log("--> Load Initial Settings")
            loadInitalSettings(setSettingArray,token) 
        }else{
            //console.log("--> Get settings form Database")
            setSettingArray(Settings)
        }       
    }).catch(err => {
        //console.log(err)
    });
}

////////////////////////////////
// Delete Settings by ID //
export const DeleteSetting = (SettingId, setSettingArray, Settings, token) => {

    // Query
    const requestBody = {
        query: `
                mutation DeleteSetting($id: ID!){
                    deleteSetting(settingId:$id){
                        _id
                    }
                }
            `,
        variables: {
            id: SettingId
        }
    }

    // Communication with Backend    
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        // Update Settings
        const updateSetting = Settings.filter(Setting => {
            return Setting._id !== SettingId;
        })
        setSettingArray(updateSetting)
    }).catch(err => {
        //console.log(err)
    });
}

///////////////////////
// Update Setting by ID //
export const UpdateSetting = (SettingItem, token) => {

    // Queries Login or CreateUser
    const requestBody = {
        query: `
                    mutation UpdateSetting(
                        $settingId: ID!, 
                        $name: String!, 
                        $description: String!,
                        $date: String!, 
                        $parameters: [String!]
                        ){
                        updateSetting(settingId: $settingId  settingInput: {
                            name: $name, 
                            description: $description,
                            date: $date, 
                            parameters: $parameters
                        }) {
                            _id
                            name
                            description
                            date
                            parameters                           
                        }
                    }
                `,
        variables: {
            name: SettingItem.name,
            date: SettingItem.date,
            description: SettingItem.description,
            settingId: SettingItem._id,
            parameters: SettingItem.parameters,
        }
    }


    // Functuion to update
    const handelResData = (resData) => {
        //setSettingItem(SettingItem)
    }

    // Communicate with Backend
    Communication(requestBody, handelResData, token);
}

/////////////////
// Create Setting // 
export const CreateSetting = (SettingItem, setSettingArray, token) => {

    // Queries Login or CreateUser
    const requestBody = {
        query: `
                mutation CreateSetting( 
                    $name: String!, 
                    $description: String!, 
                    $date: String!, 
                    $parameters: [String!]
                    ){
                    createSetting(settingInput: {
                        name: $name, 
                        description: $description, 
                        date: $date, 
                        parameters: $parameters
                    }) {
                        _id
                        name            
                        description
                        parameters                       
                    }
                }
            `,
        variables: {
            name: SettingItem.name,
            date: SettingItem.date,
            description: SettingItem.description,
            parameters: SettingItem.parameters,
        }
    }

    // Functuion to Add new Setting to SettingArray
    const handelResData = (resData) => {
        if (resData.data.createSetting) {
            //console.log("Set new Setting to list");
            //console.log(resData.data.createSetting._id);
            /* exLiId = resData.data.createSetting._id
            setExLiId(resData.data.createSetting._id)
            return (resData.data.createSetting._id) */
            setSettingArray(prevState => {
                 return [
                     ...prevState,
                     {
                         ...resData.data.createSetting
                     }
                 ];
             }); 
        }
    }

    // Communicate with Backend
    const res = Communication(requestBody, handelResData, token);
    return res

}

///////////////////
// Communication //
export const Communication = (requestBody, handelResData, token) => {

    // Communication with Backend
    //console.log(JSON.stringify(requestBody))
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        const res = handelResData(resData);
        return res
    }).catch(err => {
        //console.log(err)
        return err;
    });
}
