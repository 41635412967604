import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Checkbox } from '@material-ui/core';
import { color } from 'd3';
import parse from 'html-react-parser'
import { SelectLinkType } from '../basicComponents/SelectLinkType';

const useRowStyles = makeStyles((theme) => ({
    subTableHeader: {
        '& > *': {
            borderBottom: 'unset',
        },
        background: 'white',
        paddingBottom: '0',
        paddingTop: '0',
        background: 'rgb(240, 240, 240)',
        color: 'white'
    },
    tableHeader: {
        background: 'lightgray'
    },
    checkBoxCell: {
        width: 20,
        paddingBottom: '0',
        paddingTop: '0',
    },
    nameCell: {
        align: 'left',
        width: 'auto',
    },
    numberCell: {
        width: 120,
        align: 'center',
    },
    relevanceCell: {
        width: 120,
        align: 'right',
    },
    input: {
        width: 140,
        align: 'right',
    },
    desciptionCell: {
        align: 'left',
        [theme.breakpoints.down("sm")]: {// Mobile
            display: "none",
        }
    },
}));

export const CollapsibleTable = ({ genRelationsMap, setGenRelationsMap, linkTypeMap }) => {
    const classes = useRowStyles();

    // Total Table Checkfunktion
    const handleTableCheckBox = (event, index) => {
        const newGen = genRelationsMap.map(rel => {
            rel.selection = event.target.checked
            rel.links.map(obj => {
                obj.selection = event.target.checked
                return obj;
            })
            return rel;
        })
        setGenRelationsMap(newGen)
    }

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table" size="small" >
                <TableHead >
                    <TableRow className={classes.tableHeader}>
                        <TableCell />
                        <TableCell className={classes.checkBoxCell}>
                            <Checkbox
                                defaultChecked
                                name="top"
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onClick={(event) => handleTableCheckBox(event)}
                            />
                        </TableCell>
                        <TableCell className={classes.nameCell}>
                            <Typography className={classes.text} variant="subtitle1">Name</Typography>
                        </TableCell>
                        <TableCell className={classes.desciptionCell}>
                            <Typography className={classes.text} variant="subtitle1">Description</Typography>
                        </TableCell>
                        <TableCell className={classes.numberCell}>
                            <Typography className={classes.text} variant="subtitle1">Number of Links</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {genRelationsMap.map((row) => (
                        <Row key={row.name} row={row} setGenRelationsMap={setGenRelationsMap} genRelationsMap={genRelationsMap} linkTypeMap={linkTypeMap} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

// Function to generate Rows ans Sub Rows.
function Row(props) {
    const { row, setGenRelationsMap, genRelationsMap, linkTypeMap } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    // Parrent check funktion
    const handleRowCheckBox = (event, index) => {
        const newGen = genRelationsMap.map(rel => {
            console.log(rel.name + " " + event.target.name)
            if (rel.name == event.target.name) {
                rel.selection = event.target.checked
                rel.links.map(obj => {
                    obj.selection = event.target.checked
                    return obj;
                })
            }
            return rel;
        })
        console.log(newGen)
        setGenRelationsMap(newGen)
    }

    // Childreen check funktion
    const handleSubRowCheckBox = (event, index) => {
        const newGen = genRelationsMap.map(rel => {
            if (rel.name == row.name) {
                // Deselect parrent if ani will child will deselected
                if (rel.selection) rel.selection = false
                // Search the right Child
                let count = 0
                rel.links.map(obj => {
                    console.log(obj.targetName)
                    if (obj.targetName == event.target.name) {
                        obj.selection = event.target.checked
                    }
                    if (obj.selection) count++
                    return obj;
                })

                // If all Childs are selected then select the parrent too
                if (count == rel.links.length) rel.selection = true
            }
            return rel;
        })
        //console.log(newGen)
        setGenRelationsMap(newGen)
    }


    const handleChange = (event, newValue) => {
        const newGen = genRelationsMap.map(rel => {
            if (rel.name == row.name) {               
                rel.links.map(obj => {
                    console.log(obj.targetName)
                    if (obj.targetName == event.target.name) {
                        obj.type = event.target.value
                        obj.description = row.name +" "+ event.target.value +" "+ event.target.name
                    }
                    return obj;
                })
            }
            return rel;
        })
        //console.log(newGen)
        setGenRelationsMap(newGen)
    };



    // Parrent and Children Table
    return (
        <React.Fragment>
            <TableRow className={classes.subTableHeader}>
                <TableCell className={classes.checkBoxCell}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell className={classes.checkBoxCell}>
                    <Checkbox
                        checked={row.selection}
                        color="primary"
                        name={row.name}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        onClick={(event) => handleRowCheckBox(event)}
                    />
                </TableCell>
                <TableCell className={classes.nameCell}>
                    <Typography className={classes.text} variant="body1">{row.name}</Typography>
                </TableCell>
                <TableCell className={classes.desciptionCell} >
                    <Typography className={classes.text} variant="body1">{parse(row.description)}</Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography className={classes.text} variant="body1">{row.numberOfLinks}</Typography>
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1} marginLeft={8}>
                            <Typography variant="h6" gutterBottom component="div">
                                Links to...
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography className={classes.text} variant="subtitle1">Select</Typography>
                                        </TableCell>
                                        <TableCell className={classes.nameCell} >
                                            <Typography className={classes.text} variant="subtitle1">Name</Typography>
                                        </TableCell>
                                        <TableCell className={classes.desciptionCell} align="left">
                                            <Typography className={classes.text} variant="subtitle1">Description</Typography>
                                        </TableCell>
                                        <TableCell className={classes.relevanceCell} align="left">
                                            <Typography className={classes.text} variant="subtitle1">Link Type</Typography>
                                        </TableCell>
                                        <TableCell className={classes.relevanceCell} align="right">
                                            <Typography className={classes.text} variant="subtitle1">Relevance</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.links.map((link) => (
                                        <TableRow key={link.targetName}>
                                            <TableCell className={classes.checkBoxCell}>
                                                <Checkbox
                                                    checked={link.selection}
                                                    color="primary"
                                                    name={link.targetName}
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    onClick={(event) => handleSubRowCheckBox(event)}
                                                />
                                            </TableCell>
                                            <TableCell className={classes.nameCell} component="th" scope="row">
                                                <Typography className={classes.text} variant="body1">{link.targetName}</Typography>
                                            </TableCell>
                                            <TableCell className={classes.desciptionCell}>
                                                <Typography className={classes.text} variant="body1">{parse(link.targetDescription)}</Typography>
                                            </TableCell>

                                            <TableCell className={classes.desciptionCell}>
                                                <SelectLinkType
                                                    data={row}
                                                    onChange={handleChange}
                                                    classes={classes}
                                                    linkTypeMap={linkTypeMap}
                                                    nameIn={link.targetName}
                                                />
                                            </TableCell>

                                            <TableCell className={classes.relevanceCell} align="right">
                                                <Typography className={classes.text} variant="body1">{link.relevance}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

