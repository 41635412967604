import { Avatar, makeStyles, Tooltip } from '@material-ui/core';
import { blueGrey, green, grey, orange, yellow, brown,purple } from '@material-ui/core/colors';



// Syles
const useStyles = makeStyles((theme) => ({
    avatar: {
        marginRight: theme.spacing(0),
        color: '#fff',
        backgroundColor: theme.palette.secondary.dark,
        width: theme.spacing(3),
        height: theme.spacing(3),
        fontSize: 18
    }
}));

// Here are the definition of the Type Colors
export const TypeColors = (type) => {
    const colerInt = 900;
    let color = grey[colerInt]
    if (type === "General"||type === "general") { color = green[colerInt] }
    if (type === "Standard"||type === "standard") { color = blueGrey[colerInt] }
    if (type === "Technology") { color = orange[colerInt] }
    if (type === "Project") { color = yellow[colerInt] }
    if (type === "Method") { color = yellow[colerInt] }
    if (type === "Software") { color = brown[colerInt] }
    if (type === "Object") { color = purple[colerInt] }
    return color
}

// Val Peroperties val.type, val.size, val.fontSize
export const TypeIcons = (val) => {
    const classes = useStyles();
    return (
        <>
            {val && (
                <Tooltip title={val.type}>
                    <Avatar className={classes.avatar} style={{ backgroundColor: TypeColors(val.type), width: val.size, height: val.size, fontSize: val.fontSize}} variant="rounded" >{val.type.charAt(0).toUpperCase()}</Avatar>
                </Tooltip>
            )}
        </>
    )
}


