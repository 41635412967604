import { makeStyles } from '@material-ui/styles';
import { Button, Dialog, DialogActions} from '@material-ui/core';

const useStyles = makeStyles({
    card: {
        maxWidth: 'auto',
        margin: 'auto',
    },
});

const ImagePreviewDialog = ({ imagePreviewOpen, handleImagePreviewOpenClose, imageUrl }) => {
    const classes = useStyles();

    const handleClose = () => {
        handleImagePreviewOpenClose()
    };

    return (
        <div>
            <Dialog open={imagePreviewOpen} onClose={handleClose} maxWidth="100%" maxHeight="100%" >
                <img
                    style={{ width: 'auto', height: '100%' }}
                    src={imageUrl}
                    alt="image"
                />
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

};

export default ImagePreviewDialog;
