import React from "react";

// Is an central storrage for data.
// Here it is an javascript object, but it can be also a number ore string.
export default React.createContext({
    token:null,
    userId: null,
    permission: null,
    login:(token, userId, permission) => {},
    logout: ()=>{}
});