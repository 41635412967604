import { useState, Fragment, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';


// Import Material 
import { Button } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

//Import Components
import AuthContext from '../../context/authContext';
import { UpdateSetting } from '../../graphqlCommunication/SettingsInterface';

export const AddNewSettingDialog = ({ settingName, settings, handleGetSettings, setSettings, handleClose, handleButtonNew, render, classes }) => {

    // Context
    const contextType = useContext(AuthContext);

    const [open, setOpen] = useState(false);
    const [values, setValues] = useState({
        parameter: "",
        description: ""
    });

    const handleCancel = () => {
        setOpen(false);
    };


    const handleUpdate = () => {
        setOpen(true);
    };

    const handleChange = (event) => {
        let { value, name } = event.target;
        setValues(values => {
            return {
                ...values,
                [name]: value
            };
        });
    };

    return (
        <Fragment>
            {render(handleUpdate)}
            <Dialog open={open} onClose={handleCancel} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth="true">
                <DialogTitle id="form-dialog-title">Add New</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        id="parameter"
                        label="Parameter"
                        fullWidth
                        defaultValue=""
                        name="parameter"
                        variant="outlined"
                        value={values.parameter}
                        onChange={event => handleChange(event, values.parameter)}
                        size="small"
                    />
                </DialogContent>
                <DialogContent>
                    <TextField
                        /* autoFocus */
                        id="description"
                        label="Description"
                        fullWidth
                        defaultValue=""
                        name="description"
                        variant="outlined"
                        value={values.description}
                        onChange={event => handleChange(event, values.description)}
                        size="small"
                        multiline
                        maxRows={10}
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={event => {handleButtonNew(settingName,values); setOpen(false)}} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}