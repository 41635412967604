import { useContext, useState, } from 'react';
import { Button, ButtonGroup, Container, Paper, TextField, Typography } from '@material-ui/core';
import { NameInput } from './NameInput';
import { SelectTermType } from '../basicComponents/SelectTermType';
import { CheckPublish } from './CheckPublish';
import { DescritionInput } from './DescriptionInput';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { CreateTerm } from '../../graphqlCommunication/TermsInterface';
import wiki from 'wikipedia';
import clsx from 'clsx';
import akronim from "akronim";

// Context
import AuthContext from '../../context/authContext';
import { SelectTermDomainSpace } from '../basicComponents/SelectTermDomainSpace';
import { ViewTermDomainSpace } from '../basicComponents/ViewTermDomainSpace';
import SelectTermDomainSpaceSB from '../basicComponents/SelectTermDomainSpaceSB';
import ImagePreview from '../basicComponents/ImagePreview';
import ImagePreviewDialog from '../basicComponents/ImagePreview';
//import { ImageInput } from './ImageInput';
//import { DataNode } from 'domhandler';
//import { WikiImport } from './WikiImport';
//import DataContext from '../../context/dataContext';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(9),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        background: "lightgray",
        height: '100vh',
        maxWidth: "none",
    },
    paper: {
        padding: theme.spacing(2),
    },
    input: {
        minWidth: 160,
        maxWidth: 240,
    },
    inputWide: {
        minWidth: 160,
        maxWidth: 1200,
    },
    margin: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    text: {
        color: theme.palette.text.main,
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    button: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.text.main,
    },
}));


const emptyForm = {
    name: '',
    description: '',
    descriptionHtml: '',
    abbreviation: '',
    type: '',
    check: '',
    images: [],
    source: '',
    domain: [],
}

export const RegisterTerm = ({ setTermArray, domainSpaceDefinition, termTypeMap }) => {
    const classes = useStyles();


    // Context
    const contextType = useContext(AuthContext);
    //const dataContext = useContext(DataContext);
    //const termArray = dataContext.termArray;
    //const setTermArray = dataContext.setTermArray;

    // States and Handler for the Edit Dialog
    const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
    
    const handleImagePreviewOpenClose = () => {
        setImagePreviewOpen(false);
    };

    const handlViewImage = () => {
        console.log(data)
        setImagePreviewOpen(true);
    }

    const [data, setData] = useState(emptyForm);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleChange = (event, newValue) => {
        let { value, name } = event.target;
        if (name === "check") {
            const { checked } = event.target;
            value = checked;
        }
        setData(data => {
            //console.log(data)
            //console.log(value)


            //console.log(name) 
            return {
                ...data,
                [name]: value
            };
        });
    };

    const handleSubmit = () => {
        const { name, description, type, images, source, descriptionHtml, domain, abbreviation } = data;
        if (name !== "") {
            setFormSubmitted(true);
            const newTerm = [];
            newTerm.name = name;
            newTerm.description = description;
            newTerm.type = type;
            newTerm.images = images;
            newTerm.source = source;
            newTerm.descriptionHtml = descriptionHtml;
            newTerm.abbreviation = abbreviation;
            const d = new Date();
            let currDate = d.toISOString();
            newTerm.date = currDate;
            newTerm.domain = domain;

            // Backend Communiactaion
            CreateTerm(newTerm, setTermArray, contextType.token)
        } else {
            //console.log("New Term has not been added: Missing Name");
        }
    }


    const handlMakeAbbreviation = () => {
        const abb = akronim(data.name)
        setData(data => { return { ...data, "abbreviation": abb }; });
    }

    const handlSearchImage = () => {
        const searchQuery = data.name
        const searchUrl = `https://www.google.com/search?q=${encodeURIComponent(searchQuery)}&tbm=isch`;
        // Open a new window with the Google Image Search URL
        window.open(searchUrl, '_blank');
    }

    const handlOpenLinke = () => {
        window.open(data.source, '_blank');
    }

    const handlSearchWiki = () => {
        const { name } = data;
        if (name !== "") {
            (async () => {
                try {
                    wiki.setLang("de")
                    const page = await wiki.page(name);
                    //console.log(page.fullurl);
                    const summary = await page.summary();
                    //console.log(summary);

                    //setData(data => {return {...data,["description"]: summary.description};});
                    setData(data => { return { ...data, "description": summary.extract }; });
                    setData(data => { return { ...data, "descriptionHtml": summary.extract_html }; });
                    setData(data => { return { ...data, "source": page.fullurl }; });
                    if (summary.originalimage) {
                        setData(data => { return { ...data, "images": summary.originalimage.source }; });
                    }
                } catch (error) {
                    //console.log(error);
                    //=> Typeof wikiError
                }
            })()
        } else {
            //console.log("Name ist empty");
        }
    }


    const handleResetForm = () => {
        setData(emptyForm);
        setFormSubmitted(false);
    }

    if (!formSubmitted) {
        return (
            <Container className={classes.container}>
                <Paper className={classes.paper}>
                    <Typography className={classes.text} variant="h6">Create a new Item</Typography>
                    <div>
                        <NameInput data={data} onChange={handleChange} classes={classes} name={"name"} />
                        <Button variant="contained" color="primary" onClick={handlSearchWiki} className={classes.button}>Search in Wiki</Button>
                        <TextField
                            name="abbreviation"
                            value={data.abbreviation}
                            onChange={event => handleChange(event, data.abbreviation)}
                            id="standard-basic"
                            label="Abbreviation"
                            className={clsx(classes.input, classes.margin)}
                            variant="outlined"
                            size="small"
                        />
                        <Button variant="contained" color="primary" onClick={handlMakeAbbreviation} className={classes.button}>Make Abb.</Button>

                        <SelectTermType data={data} onChange={handleChange} classes={classes} termTypeMap={termTypeMap} />

                        <SelectTermDomainSpace data={data} onChange={handleChange} classes={classes} domainSpaceDefinition={domainSpaceDefinition} />

                        {/*  <SelectTermDomainSpaceSB data={data} setData={setData} domainSpaceDefinition={domainSpaceDefinition}></SelectTermDomainSpaceSB> */}


                        <DescritionInput data={data} onChange={handleChange} classes={classes} />
                        <TextField
                            id="images"
                            label="Image URL"
                            fullWidth
                            defaultValue={data.images[0]}
                            name="images"
                            variant="outlined"
                            size="small"
                            value={data.images}
                            className={clsx(classes.inputWide, classes.margin)}
                            onChange={event => handleChange(event, data.images)}
                        />
                        <Button variant="outlined" color="primary" onClick={handlSearchImage} className={classes.button}>Search Image</Button>
                        <Button variant="outlined" color="primary" onClick={handlViewImage} className={classes.button}>View</Button>
                        
                        <TextField
                            id="source"
                            label="Source URL"
                            fullWidth
                            defaultValue={data.source}
                            name="source"
                            variant="outlined"
                            size="small"
                            value={data.source}
                            className={clsx(classes.inputWide, classes.margin)}
                            onChange={event => handleChange(event, data.source)}

                        />
                        <Button variant="outlined" color="primary" onClick={handlOpenLinke} className={classes.button}>Open</Button>

                        {/* <CheckPublish data={data} onChange={handleChange} classes={classes} /> */}
                    </div>
                    <div>
                        <Button variant="contained" color="primary" onClick={handleSubmit} className={classes.button}>Add to list</Button>
                    </div>

                </Paper>

                <ImagePreviewDialog
                    imagePreviewOpen={imagePreviewOpen}
                    handleImagePreviewOpenClose={handleImagePreviewOpenClose}
                    imageUrl={data.images}
                />


            </Container>
        )
    } else {
        return (
            <Container className={classes.container}>
                <Paper className={classes.paper}>
                    <Typography className={classes.text} variant="h6" >There is a new Term on the List!</Typography>
                    <Typography className={classes.text} variant="body1" >Name: {data.name}</Typography>
                    <Typography className={classes.text} variant="body1">Description: {data.description}</Typography>
                    <Typography className={classes.text} variant="body1">Type: {data.type}</Typography>
                    <Typography className={classes.text} variant="body1" >Image URL: {data.images[0]}</Typography>
                    <Typography className={classes.text} variant="body1">Domain Space: {data.domain}</Typography>
                    <Typography className={classes.text} variant="body1" >Source {data.source}</Typography>
                    <Typography className={classes.text} variant="body1">Public: {data.check}</Typography>
                    <Button variant="contained" color="primary" onClick={handleResetForm} className={classes.button}>Add new Term</Button>
                    <Button variant="outlined" color="primary" className={classes.button} >
                        <Link to="/" className={classes.link}>
                            Back to List
                        </Link>
                    </Button>
                </Paper>


            </Container>
        )
    }
}
