//const BACKEND_ADRESS = 'http://localhost:8000/graphql'
//const BACKEND_ADRESS = 'http://graphqltuorial-env.eba-bmibwfqm.us-east-2.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'http://limabackend.eba-bmibwfqm.us-east-2.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'http://limanticbackend-env.eba-ktayy22j.eu-north-1.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'https://backend.app.limantic.com/graphql'

// Umgebungsvariablen
// Diese Funktionieren nur mit dem consolen aufruf: npm start .env
// diese werden in der Entwicklungsumgebung im Projekt im file .env gepflegt
// Beschrieben auf: https://create-react-app.dev/docs/adding-custom-environment-variables/
const BACKEND_ADRESS = process.env.REACT_APP_BACKEND_ADRESS; 
//test

// Login //
export const Login = (email, password, contextType) => {

    if (email.trim().length === 0 || password.trim().length === 0) {
        return;
    }

    // Queries Login 
    let requestBody = {
        query: `
            query Login($email: String!, $password: String!){
                 login(email: $email, password: $password) {
                    userId
                    token
                    tokenExpiration
                    permission
                 }
             }
         `,
        variables: {
            email: email,
            password: password
        }
    }

    // Communication to Backend
    Communication(requestBody, contextType)
}



// Singup for a new User //
export const Signup = (email, password, permission, contextType) => {
    if (email.trim().length === 0 || password.trim().length === 0) {
        return;
    }

    // Query CreateUser
    let requestBody = {
        query: `
                mutation CreateUser($email: String!, $password: String!, $permission: String!) {
                    createUser(userInput: {email: $email, password: $password, permission: $permission }) {
                        _id
                        email
                    }
                }
            `,
        variables: {
            email: email,
            password: password,
            permission: permission
        }
    }

    // Communication to Backend
    Communication(requestBody, contextType)
}

// Update existing User //
export const UpdateUser = (userId, email, password, permission, contextType) => {
    if (email.trim().length === 0 || password.trim().length === 0) {
        return;
    }

    // Query CreateUser
    let requestBody = {
        query: `
                mutation UpdateUser($email: String!, $password: String!, $permission: String!,$userId: ID!) {
                    updateUser(userId:$userId, userInput: {email: $email, password: $password, permission: $permission }) {
                        _id
                        email
                        permission
                    }
                }
            `,
        variables: {
            userId: userId,
            email: email,
            password: password,
            permission: permission
        }
    }

    // Communication to Backend
    Communication(requestBody, contextType)
}


// Communication with backend
const Communication = (requestBody, contextType) => {

    ////console.log(JSON.stringify(requestBody))
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + contextType.token,
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        if (resData.data.login.token) {
            contextType.login(
                resData.data.login.token,
                resData.data.login.userId,
                resData.data.login.tokenExpiration,
                resData.data.login.permission
            )
        }
        //console.log(resData)
    }).catch(err => {
        //console.log(err)
    });
}


// Get all User //
export const getUser = (setUsers) => {

    // Queries Login 
    let requestBody = {
        query: `
            query {
                users{
                    _id
                    email
                    permission
                    password
                }
            }
         `
    }

    // Communication to Backend
     ////console.log(JSON.stringify(requestBody))
     fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        setUsers(resData.data.users)
        //console.log(resData)
    }).catch(err => {
        //console.log(err)
    });

}


// Get User by id //
export const GetUserById = (userId, setUser) => {

    // Query userById
    let requestBody = {
        query: `
                mutation userById($userId: ID!) {
                    userById(userId:$userId) {
                        _id
                        email
                        password
                        permission
                    }
                }
            `,
        variables: {
            userId: userId
        }
    }

    // Communication to Backend
     ////console.log(JSON.stringify(requestBody))
     fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        setUser(resData.data.userById)
        //console.log(resData)
    }).catch(err => {
        //console.log(err)
    });

}

// Delete a User! Warning!!! 
export const deleteUser = (userId) => {

    if (userId.trim().userId === 0) {
        return;
    }

    // Query CreateUser
    let requestBody = {
        query: `
                mutation DeleteUser($userId: ID!) {
                    deleteUser(userId: $userId) {
                        _id
                        email
                    }
                }
            `,
        variables: {
            userId: userId,
        }
    }   

    // Communication to Backend
     ////console.log(JSON.stringify(requestBody))
     fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        //console.log(resData)
    }).catch(err => {
        //console.log(err)
    });

}
