import React from 'react';
import clsx from 'clsx';
import { Select, InputLabel, MenuItem, FormControl, Checkbox, FormControlLabel, Divider } from '@material-ui/core';


export const SelectTermDomainSpace = ({ data, onChange, classes, domainSpaceDefinition }) => {

    let domainSpaceDefinitionArray = []
    Object.keys(domainSpaceDefinition).map((dom) => {
        domainSpaceDefinitionArray = domainSpaceDefinitionArray.concat(dom);
    })
    
    const items = domainSpaceDefinitionArray.map((val, index) => {      
        return (
            <MenuItem value={val}>{val}</MenuItem>
        )
    });


    return (
        <React.Fragment>
            <FormControl size="small" variant="outlined" className={clsx(classes.input, classes.margin)} >
                <InputLabel id="simple-select-label">Domain Space</InputLabel>
                <Select
                    name="domain"
                    labelId="simple-select-label"
                    id="simple-select"                
                    value={data.domain}
                    onChange={event => onChange(event, data.domain)}
                    label="Domain Space"
                >

                    <MenuItem aria-label="None" value="" />
                    {items}
                    
                </Select>
            </FormControl>
        </React.Fragment>
    );
}

{/* <MenuItem value="All">All</MenuItem>
                    <MenuItem value="PLM">PLM</MenuItem>
                    <MenuItem value="MCAD">MCAD</MenuItem>
                    <MenuItem value="Automotive">Automotive</MenuItem>
                    <MenuItem value="Botanic">Botanic</MenuItem> */}