import { useState, forwardRef } from 'react';
import { Button, makeStyles, Grid, Paper, Container, Typography, Snackbar } from '@material-ui/core';
import XLSX from 'xlsx';
//import { make_cols } from './MakeColumns';
//import { SheetJSFT } from './Types';
import MuiAlert from '@material-ui/lab/Alert';

// Style
const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(9),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        background: "lightgray",
        height: "100vh",
        maxWidth: "none",
    },
    paper: {
        height: "95%",
        padding: theme.spacing(2),
    },
    mainGrid: {
        margin: "0px",
    },
    button: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    text: {
        color: theme.palette.text.main,
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    }
}));

const SheetJSFT = [
    "xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
].map(function (x) { return "." + x; }).join(",");

// Alert 
const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Main Function
export const ExcelReader = ({ setDataFormImport, termArray}) => {
    const classes = useStyles();

    // Use state constances
    const [file, setFile] = useState("");
    const [openAlert, setOpenAlert] = useState(false)


    // Select File Functions
    const handleSelectFile = (e) => {
        const files = e.target.files;
        if (files && files[0]) setFile(files[0]);
    };

    // Import from Excel
    const handleImportFile = (e) => {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e) => {
            //console.log("Load form Excel");
            /* Parse data */
            const bstr = e.target.result;
            //console.log(bstr);
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname]
            //console.log(ws);
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws);
            ////console.log(data.length);
            /* Update state */
            //setData(data);
            //console.log(data);
            //setCols(make_cols(ws['!ref']));
            //console.log(JSON.stringify(data, null, 2));
            setDataFormImport(data)
        };

        if (rABS) {
            reader.readAsBinaryString(file);
        } else {
            reader.readAsArrayBuffer(file);
        };
        setOpenAlert(true)
    }


    // Export 
    const handleExportToFile = (e) => {
        var workbook = XLSX.utils.book_new();
        //console.log(termArray);
        const rows = termArray.map(row => ({
            _id: row._id,
            name: row.name,
            description: row.description,
            date: row.date,
            type: row.type,
            creator: row.creator.email,
            images: row.images[0],
            source: row.source,
            relations: row.relations,
            domain: row.domain[0],
          }));

        const worksheet = XLSX.utils.json_to_sheet(rows)
        XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");
        XLSX.writeFile(workbook, "Report.xlsb");
    }


    // Snackbar
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false)
    }

    // Return
    return (
        <Container className={classes.container}>
            <Paper className={classes.paper}>
                <Typography className={classes.text} variant="h6">Import data from Excel File</Typography>
                <Grid className={classes.mainGrid} container spacing={1}>
                    <Grid item xs={12} >
                        <Button className={classes.button} variant="outlined" color="primary" component="label" >
                            Select File
                            <input
                                type="file"
                                accept={SheetJSFT}
                                onChange={handleSelectFile}
                                hidden
                            />
                        </Button>
                        <Typography className={classes.text} variant="body1" >File Name: {file.name}</Typography>
                        <Button className={classes.button} variant="outlined" color="primary" onClick={handleImportFile}>Import Data</Button>

                        <Button className={classes.button} variant="outlined" color="primary" onClick={handleExportToFile}>Export Data</Button>

                        
                    </Grid>
                </Grid>
            </Paper>
            <Snackbar
                open={openAlert}
                autoHideDuration={3000}
                onClose={handleClose}
                message="Note archived"
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
                <Alert onClose={handleClose} severity="success">
                    Data from Excel!
                </Alert>
            </Snackbar>
        </Container>
    )
}
