import { Typography, AccordionSummary, AccordionDetails, Accordion, withStyles, makeStyles, Divider } from '@material-ui/core'

// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Style
const useStyles = makeStyles((theme) => ({    
    divider:{
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(-2),
        marginBottom: 0,
    },
    title:{
        color: theme.palette.primary.contrastText,
    },
    text:{
        color: theme.palette.text.light,
        marginTop: theme.spacing(1),
    },
    icon:{
        color: theme.palette.primary.contrastText,
    }
}));

// Accordion ReStyle
const AccordionStyle = withStyles((theme) => ({
    root: {
        border: '1px solid (1, 1, 1,0.1)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
}))(Accordion);

const AccordionDetailsStyle = withStyles((theme) => ({
    root: {
        padding: theme.spacing(0),
    },
}))(AccordionDetails);

const AccordionSummaryStyle = withStyles((theme) => ({
    root: {
        color:theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,//'rgba(90, 118, 131, 0.1)',
        borderBottom: '1px solid rgba(90, 118, 131,0)',
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(-2),
        marginBottom: 0,
        minHeight: 28,
        '&$expanded': {
            minHeight: 28,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
}))(AccordionSummary);

// Main Function
export const AccordionMod = ({title, text, SubComponent, defaultExp }) => {
    const classes = useStyles();
    return (
        <div>        
        <AccordionStyle square defaultExpanded={defaultExp}>
            <AccordionSummaryStyle
                expandIcon={<ExpandMoreIcon className={classes.icon} />}
                id="panel1a-header1">
                <Typography  className={classes.title} >{title}</Typography>
            </AccordionSummaryStyle>
            <AccordionDetailsStyle>
                <Typography className={classes.text} variant="body1" paragraph>{text}</Typography>
                <SubComponent/>
            </AccordionDetailsStyle>
        </AccordionStyle>
        <Divider className={classes.divider}/>
        </div>
    );
}
