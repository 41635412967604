import React from 'react';
//import clsx from 'clsx';
import { Select, InputLabel, MenuItem, FormControl } from '@material-ui/core';


export const SelectGraphType = ({ type, onChange ,classes }) => {
    return (
        <React.Fragment>
            <FormControl variant="outlined" className={classes.autocomplete} >
                <InputLabel id="simple-select-label">Link View</InputLabel>
                <Select
                    name="Link View"
                    labelId="simple-select-label"
                    id="simple-select"
                    value={type}
                    onChange={event => onChange(event, type)}
                    label = "Link View"                                        
                >
                    <MenuItem value="forceGraph">Force Graph</MenuItem>
                    <MenuItem value="flowChart">Flow Chart</MenuItem>
                </Select>
            </FormControl>
        </React.Fragment>
    );
}