import { AppBar, makeStyles, Toolbar, Typography, alpha, Badge, Fab, Drawer, Container, MenuItem, IconButton, Menu } from '@material-ui/core';
import { Notifications } from '@material-ui/icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import LooksOneIcon from '@material-ui/icons/LooksOne';
import LooksTwoIcon from '@material-ui/icons/LooksTwo';
import Looks3Icon from '@material-ui/icons/Looks3';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import { ReactComponent as Logo } from '../../logo.svg';
import { ReactComponent as LogoText } from '../../logoText.svg';
import { Fragment, useState } from 'react';
import { BarItems } from './BarItems';
import { SelectTermDomainSpace } from '../basicComponents/SelectTermDomainSpace';
import ViewTermDomainSpaceSB from '../basicComponents/ViewTermDomainSpaceSB';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: "flex",
        justifyContent: "space-between"
    },
    lage: {
        color: "white",
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "block"
        },
    },
    small: {
        color: "white",
        display: "block",
        [theme.breakpoints.up("md")]: {
            display: "none"
        },
    },
    search: {
        display: "flex",
        color: "white",
        alignItems: "center",
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        borderRadius: theme.shape.borderRadius,
        width: "50%",

        [theme.breakpoints.down("sm")]: {
            display: "none"
        },
    },
    input: {
        color: "white",
        marginLeft: theme.spacing(1),
    },
    icons: {
        display: "flex",
        color: "white",
        alignItems: "center",
    },
    badge: {
        marginRight: theme.spacing(2),
    },
    menu: {
        marginRight: theme.spacing(1),
    },
    menuIcons: {
        marginRight: theme.spacing(1),
    },
    fabNav: {
        background: "none",
        boxShadow: "none",
        color: theme.palette.common.white,
    },
    container: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    headerContainer: {
        backgroundColor: theme.palette.primary.main,
        height: "54px",
    },
    formControl: {
        backgroundColor: theme.palette.primary.main,
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(0),
        width: 65,
    },
    textIcon: {
        paddingLeft: theme.spacing(1),   
    },
    input: {
        minWidth: 160,
        maxWidth: 240,
        background:'white',
      
    },
    
}));


const emptyForm = {
    domain: [],
}


export const Navbar = ({ handleLogout, setViewSet, setDomainMap, domainMap, domainSpaceDefinition, setDomainSpaceDefinition }) => {
    const classes = useStyles();

    const [data, setData] = useState(emptyForm);

    const handleChange = (event, newValue) => {
     
    };

    const handleButtonLogout = () => {
        handleLogout()
    }


    // View constelations
    const handleButtonViewTwo = () => {
        setViewSet([5, 0, 6])
    }

    const handleButtonView3 = () => {
        setViewSet([2, 6, 3])
    }

    const handleButtonViewFlex = () => {
        setViewSet([-1, 6, 3])
    }

    const handleButtonGraphOnly = () => {
        setViewSet([0, 11, 0])
    }

    /*   const [age, setAge] = useState('');
  
      const handleChange = (event) => {
          setAge(event.target.value);
      }; */


    const [dawerState, setDawerState] = useState(false);

    // Toggle Drawer
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDawerState(open);
    };


    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const ITEM_HEIGHT = 48;


    return (
        <div>
            <AppBar >
                <Toolbar className={classes.toolbar}>
                    <div className={classes.logo}>
                        <Typography variant="h4" className={classes.lage}>
                            <Logo />
                        </Typography>
                        <Typography variant="h6" className={classes.small}>
                            <div className={classes.icons}>
                                <Fab
                                    className={classes.fab}
                                    onClick={toggleDrawer(true)}
                                    size="small"
                                >
                                    <MenuIcon />
                                </Fab>
                                <LogoText className={classes.textIcon}/>
                            </div>
                        </Typography>
                    </div>
                    {/* 
                    <div className={classes.search}>
                        <Search></Search>
                        <InputBase className={classes.input} placeholder="Search..."></InputBase>
                    </div>
                    */}
                    <div className={classes.icons}>

                        {/* More Button for View Set */}
                        <ViewTermDomainSpaceSB setDomainMap={setDomainMap} domainMap={domainMap} domainSpaceDefinition={domainSpaceDefinition} setDomainSpaceDefinition={setDomainSpaceDefinition}/>
                        
                        <IconButton
                            className={classes.lage}
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={handleClick}
                        >
                            <MoreHorizIcon />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '20ch',
                                },
                            }}
                        >
                            <MenuItem value={10} onClick={handleButtonViewTwo}>
                                <LooksTwoIcon className={classes.menuIcons} />
                                Term & Detail 
                            </MenuItem>

                            <MenuItem value={20} onClick={handleButtonView3}>
                                <Looks3Icon className={classes.menuIcons} />
                                View All
                            </MenuItem>

                            <MenuItem value={30} onClick={handleButtonViewFlex}>
                                <ViewColumnIcon className={classes.menuIcons} />
                                All Fexible
                            </MenuItem>
                            <MenuItem value={40} onClick={handleButtonGraphOnly}>
                                <LooksOneIcon className={classes.menuIcons} />
                                Graph only
                            </MenuItem>
                        </Menu>


                        <Badge badgeContent={4} color="secondary" className={classes.badge}>
                            <Notifications />
                        </Badge>
                        <Fab
                            className={classes.fabNav}
                            onClick={handleButtonLogout}
                            size="small">
                            <ExitToAppIcon />
                        </Fab>
                    </div>
                </Toolbar>
            </AppBar>

            <Fragment>
                <Drawer className={classes.drawer} anchor={'left'} open={dawerState} onClose={toggleDrawer(false)}>
                    <Container className={classes.headerContainer} />
                    <Container className={classes.container}>
                        <BarItems setDawerState={setDawerState} />
                    </Container>
                </Drawer>
            </Fragment>
        </div>
    );
}

