import React, { useRef, useEffect, useState } from "react";
import * as d3 from 'd3'
import { generateRelationsByLinks } from "../generateRelations";
import { generateFilteredRelations, generateIdMap, generateTermArray } from "../../mainView/ServicesMainView";


// Link definition 
function linkArc(d) {
    const r = Math.hypot(d.target.x - d.source.x, d.target.y - d.source.y) * 2;
    return `
      M${d.source.x},${d.source.y}
      A${r},${r} 0 0,1 ${d.target.x},${d.target.y}
    `;
}

const height = 900
const width = 600

/* const width = window.innerWidth - 30;
const height = window.innerHeight - 30; */


// Create a dataset
let data = {
    "links": [
        { source: "Microsoft", target: "Amazon", type: "licensing" },
        { source: "Microsoft", target: "HTC", type: "licensing" },
        { source: "Samsung", target: "Apple", type: "suit" },
        { source: "Motorola", target: "Apple", type: "suit" },
        { source: "Nokia", target: "Apple", type: "resolved" },
        { source: "HTC", target: "Apple", type: "suit" },
        { source: "Apple", target: "HTC", type: "suit" },
    ],
    "nodes": [
        { id: "Microsoft" },
        { id: "Amazon" },
        { id: "HTC" },
        { id: "Samsung" },
        { id: "Apple" },
        { id: "Motorola" },
        { id: "Nokia" },
    ]
}

let types = [
    "besteht aus",
    "ist Teil von",
    "beinhaltet",
    "ist auch",
    "used in",
    "",
]

let color = d3.scaleOrdinal(types, d3.schemeCategory10)

// Drag of nodes
let drag = simulation => {

    function dragstarted(event, d) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
    }

    function dragged(event, d) {
        d.fx = event.x;
        d.fy = event.y;
    }

    function dragended(event, d) {
        //if (!event.active) simulation.alphaTarget(0);
        //d.fx = null;
        //d.fy = null;
        d.fx = event.x;
        d.fy = event.y;
    }

    return d3.drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended);
}

function ZoomableSVG({ children }) {
    //console.log("ZoomableSVG");
    const svgRef = useRef();
    const [k, setK] = useState(1);
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    useEffect(() => {
        const zoom = d3.zoom().on("zoom", (event) => {
            const { x, y, k } = event.transform;
            setK(k);
            setX(x);
            setY(y);
        });
        d3.select(svgRef.current).call(zoom);
    }, []);
    return (
        <svg ref={svgRef} width="100%" height={height}>
            <g transform={`translate(${x},${y})scale(${k})`}>{children}</g>
        </svg>
    );
}



// Main Function
export const ForceGraphD3 = (props) => {

    const [toolTip, setToolTip] = useState("...")



    const { termArray, termItem, linkArray, setTermItem, filter } = props;

    // Filter Terms and find Links
    const id = termItem._id;
    //const relations = generateRelations(termArray);
    let relations = generateRelationsByLinks(linkArray);
    let termArrayNew = termArray;
    ////console.log(relations)
    // Find Ids
    const idMap = generateIdMap(relations, id)

    if (filter === true) {
        // Filter Relations
        relations = generateFilteredRelations(relations, idMap)
        // Filer TermArray
        termArrayNew = generateTermArray(termArray, idMap, id, true)
    }

    termArrayNew = termArrayNew.map(item => {
        return {
            ...item,
            id: item._id
        };
    });

    const data3 = {
        nodes:
            termArrayNew,
        links:
            relations
    }

    const links = data3.links.map(d => Object.create(d));
    const path = data3.links.map(d => Object.create(d));
    const nodes = data3.nodes.map(d => Object.create(d));


    // Referenz für das zu renderene SVG element
    const ref = useRef()
    const tltpRef = useRef()
    const paraRef = useRef()

    function mouseHandlerNode(svg) {
        svg.attr("cursor", "crosshair")
            .on('click', function (event, d, i) {
                setTermItem(d)

                d3.select(this)
                    .attr("fill-opacity", 0.8)

                //console.log("Mouse down")
                //console.log(d)
                //console.log(d.label)

            })
            .on('mouseout', function (d, i) {
                d3.select(this).transition()
                    .duration('50')
                    .attr("fill-opacity", 1)
            });
    }


    function handleZoom(e) {
        d3.select('svg g')
            .attr('transform', e.transform);
    }



    // Führt d3-Code aus, wenn die Komponente montiert wird
    useEffect(() => {



        // Tool Tip for the link type
        var tooltip = d3.select(tltpRef.current)
            .attr("class", "tooltip")
            .style("opacity", 0);

        // Dynamic legend for the link type
        var linkTypePara = d3.select(paraRef.current)
            .style("opacity", 1);

        // Clean up the svg
        d3.select(ref.current).selectAll("g").remove();



        // New SVG
           const svg = d3.select(ref.current)
             .attr("viewBox", [-width / 2, -height / 3, width, height])
             .append('g')
             .attr("viewBox", [-width / 2, -height / 3, width, height])
             .style("font", "12px sans-serif")
              .call(d3.zoom().on("zoom", function (event) {
                 svg.attr("transform", event.transform)
             }))  

       /*  const svg = d3.select(ref.current)
            .attr("viewBox", [-width, -height, "100%", height])
            .append('g')
            .attr("viewBox", [-width, -height, width, height])
            .style("font", "12px sans-serif")
            .call(d3.zoom().on("zoom", function (event) {
                svg.attr("transform", event.transform)
            })) */

        // Simulation
        const simulation = d3.forceSimulation(nodes)
            .force("link", d3.forceLink(links).id(d => d.id))
            .force("charge", d3.forceManyBody().strength(-3000))
            .force("x", d3.forceX())
            .force("y", d3.forceY())
            .force("center", d3.forceCenter(-110, -110))
            // See: https://observablehq.com/@maliky/testing-the-d3-forces-parameters
            .alpha(0.5)
            .alphaDecay(0.1)
            .velocityDecay(0.5)

        simulation.on("tick", () => {
            path.attr("d", linkArc);
            node.attr("transform", d => `translate(${d.x},${d.y})`)
        });

        // Arrow Marker   
        svg.append("defs").selectAll("marker")
            .data(types)
            .join("marker")
            .attr("id", d => `arrow-${d.replaceAll(' ', '_')}`)
            .attr("viewBox", [0, -5, 10, 10])
            .attr("refX", 15)
            .attr("refY", -0.3)
            .attr("transform", "rotate(0,0,0)")
            .attr("markerWidth", 6)
            .attr("markerHeight", 6)
            .attr("orient", "auto-start-reverse")
            .append("path")
            .attr("fill", color)
            .attr("d", "M0,-5L10,0L0,5")

        // Links        
        const link = svg.append("g")
            .attr("fill", "none")
            .attr("stroke-width", 2)

        const path = link.selectAll("path")

            .data(links)
            .text(d => `link-${d.source}`)
            .join("path")
            .attr("stroke", d => color(d.type))
            .attr('id', d => `link-${d.source}`)
            .attr("marker-end", d => `url(#arrow-${d.type.replaceAll(' ', '_')})`)
            .attr("cursor", "crosshair")
            .on("mouseover", function (event, d) {
                setToolTip(d.label)
                // Info text for Linkt Type
                linkTypePara.text(" " + d.label)
                    .style("color", color(d.type))

                // Tooltip at the pathes    
                tooltip.transition()
                    .duration(100)
                    .style("position", "absolute")
                    .style("left", (event.pageX) + "px")
                    .style("top", (event.pageY - 35) + "px")
                    .style("background-color", "white")
                    .style("border", `2px solid ${color(d.type)}`)
                    .style("border-radius", "5px")
                    .style("padding", "8px 12px")
                    .style("opacity", .9);
                tooltip.text(d.label)
                    .style("color", color(d.type));
            })

            .on("mouseout", function (d) {
                tooltip.transition()
                    .duration(2000)
                    .style("opacity", 0);
            });
        //.call(mouseHandlerLink)


        //console.log("link")
        //console.log(link)

        // Nodes    
        const node = svg.append("g")
            .attr("fill", "#5a7683")
            .attr("stroke-linecap", "round")
            .attr("stroke-linejoin", "round")
            .selectAll("g")
            .data(nodes)
            .attr("id", d => "id_" + d.id)
            .join("g")
            .call(drag(simulation))
            .call(mouseHandlerNode);

        node.attr("fill", d => { if (d.id === id) { return "#2f4a56" } })
        node.attr("font-size", d => { if (d.id === id) { return 20 } })

        node.append("circle")
            .attr("stroke", "white")
            .attr("stroke-width", 1.5)
            .attr("r", 8);

        // Text on the nodes
        node.append("text")
            .attr("x", 12)
            .attr("y", "0.31em")
            .attr("transform", "rotate(10,0,0)")
            .text(d => d.name)
            .clone(true).lower()
            .attr("fill", "none")
            .attr("stroke", "white")
            .attr("stroke-width", 3);

        svg.exit().remove();

    }, [termItem, termArray])

    return (
        <>
            <div>
                <div align="right">Link Type: <span ref={paraRef}></span></div>
                <div ref={tltpRef}></div>
                <ZoomableSVG>
                    <svg ref={ref}>
                    </svg>
                </ZoomableSVG>
            </div>
        </>
    )

}