import React, { useContext } from 'react';
import { generateRelationsByLinks } from "../generateRelations";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { CardHeader, Chip, IconButton, withStyles } from '@material-ui/core';
//import grey from '@material-ui/core/colors/grey';
import { generateFilteredRelations, generateIdMap, generateMultiIdMap, generateTermArray } from '../../mainView/ServicesMainView';
import { TypeIcons } from '../../mainView/TypeIcons';
//import { makeStyles } from '@material-ui/core/styles';
//import { theme } from '../../../theme';
import DataContext from '../../../context/dataContext';

import Card from '@material-ui/core/Card';
//import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { MarkerType } from 'react-flow-renderer';
import { GetAllViews } from '../../../graphqlCommunication/ViewsInterface';

const defaultValues = {
    position: { x: 0, y: 0 },
};

// Secific Definition for the title Button
const BootstrapButton = withStyles({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: '1.5rem',
        fontWeight: 400,
        lineHeight: 1.334,
        letterSpacing: '0em',
        padding: '0px 0px',
        border: '0px solid',
        lineHeight: 1.5,
        backgroundColor: 'none',
        borderColor: 'none',
        fontFamily: [
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
        ].join(','),

        '&:hover': {
            backgroundColor: 'none',
            borderColor: 'none',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: 'none',
            borderColor: 'none',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,0,0,0)',
        },
    },
})(Button);


export const CreateElementsForFlowChart = (termArray, termItem, setTermItem, linkArray, viewArray, classes, viewSet, setReLoadGraph, loadElementsHandle, setViewArray, token) => {

    const handleReloadGraph = (obj) => {
        setTermItem(obj)
        loadElementsHandle();
        //console.log("---> handleReloadGraph")
    };

    const handleShowInDetailView = (obj) => {
        setReLoadGraph(false)
        setTermItem(obj)
    }


    GetAllViews(setViewArray, token);



    // Style of Node content
    const GraphNodeContetnt = ({ obj }) => {

        let relationsNr = 0;
        if (linkArray) {
            relationsNr = linkArray.filter(link => (link.toId === obj._id || link.fromId === obj._id)).length
        }

        //console.log("relationsNr " + relationsNr)


        return (
            <>
                {viewSet.media && (
                    <>
                        {(obj.images[0] !== "") && (
                            <CardMedia
                                className={classes.media}

                                alt="no Image"
                                image={obj.images[0]}
                                title="no Image"
                            />
                        )}
                    </>
                )}
                <CardHeader
                    className={classes.cardContent}
                    avatar={
                        <TypeIcons type={obj.type} size={"40px"} fontSize={32} />
                    }
                    action={
                        <>
                            <Chip
                                className={classes.chip}
                                label={relationsNr}
                                onClick={() => handleReloadGraph(obj)}
                                variant="outlined"
                                color="primary"
                                aria-label="Show"
                                component="label"
                            />
                            {/* <IconButton onClick={() => handleReloadGraph(obj)} color="primary" aria-label="Show" component="label">
                                <OpenInNewIcon />
                            </IconButton> */}
                        </>
                    }
                    titleTypographyProps={{ variant: 'h5' }}
                    title={
                        <BootstrapButton disableRipple onClick={() => handleShowInDetailView(obj)} color="primary">
                            {obj.name}
                        </BootstrapButton>
                    }
                    subheader={obj.type}
                    subheaderTypographyProps={{ variant: 'body1' }}
                >
                </CardHeader>
                {viewSet.description && (
                    <CardContent className={classes.cardContent}>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {obj.description}
                        </Typography>
                    </CardContent>
                )}
                {viewSet.buttons && (
                    <CardActions className={classes.cardContent}>
                        <Button onClick={() => handleReloadGraph(obj)} size="small" color="primary">
                            Show
                        </Button>
                        {/* <Button size="small" color="primary">
                            Edit
                        </Button> */}
                    </CardActions>
                )}
            </>
        );
    };

    // Filter Terms and find Links
    const id = termItem._id;

    // Style of Node content
    const GraphNode = ({ obj, objColor }) => {
        const randKey = Math.random
        return (
            <div className="graphNode" style={{ wordBreak: "keep-all" }}>
                {id === obj.id ? (
                    <>
                        <Card key={"key_" + randKey} className={classes.selectedCard}>
                            <GraphNodeContetnt obj={obj} />
                        </Card>
                    </>
                ) : (
                    <Card key={"key_" + randKey} className={classes.card} style={{ background: objColor }}>
                        <GraphNodeContetnt obj={obj} />
                    </Card>
                )}
            </div>
        );
    };


    //const relations = generateRelations(termArray);
    const relations = generateRelationsByLinks(linkArray);
    // Find Ids
    const idMap = generateIdMap(relations, id)
    // Filter Relations
    let filteredRelations = generateFilteredRelations(relations, idMap)
    // Filer TermArray
    let filteredTermArray = generateTermArray(termArray, idMap, id, true)



    const showView = true

    // Read from existing View
    if (viewSet.showView) {



        if (termItem.views) {
            if (termItem.views.length > 0) {
                const termViewID = termItem.views[0]

                let currentView = {}
                viewArray.map((view) => {
                    console.log(view._id === termViewID)
                    if (view._id === termViewID) {
                        currentView = view;
                    }
                })

                console.log("currentView");
                console.log(currentView);


                /*             // Links
                            let viewLinkMap = []
                            currentView.links.map((link)=>{
                                viewLinkMap.push(eval('(' + link + ')'))
                            })
                            
                            console.log("viewLinkMap");
                            console.log(viewLinkMap);
                            console.log(linkArray);            
                
                          
                
                            let filteredLinkViewArray = []
                            linkArray.map((link)=>{                
                                viewLinkMap.map((viewLink)=>{
                                    console.log(viewLink.id===link._id);
                                    if(viewLink.id===link._id){
                                        filteredLinkViewArray.push(link)
                                    }
                                })
                            })
                
                            console.log("filteredLinkViewArray");
                            console.log(filteredLinkViewArray); */

                // Terms
                let viewTermMap = []
                if (currentView.terms) {
                    currentView.terms.map((term) => {
                        viewTermMap.push(eval('(' + term + ')'))
                    })
                }

                //console.log(viewTermMap);


                let filteredTermViewArray = []
                let ids = []
                termArray.map((term) => {
                    viewTermMap.map((viewTerm) => {
                        if (viewTerm.id === term._id) {
                            filteredTermViewArray.push(term)
                            ids.push(term._id)
                        }
                    })
                })
                console.log("filteredTermViewArray")
                console.log(filteredTermViewArray)

                filteredTermArray = filteredTermViewArray

                const idMapMulti = generateMultiIdMap(relations, ids)
                // Filter Relations
                filteredRelations = generateFilteredRelations(relations, idMapMulti)


            }
        } else {
            console.log("noId");
        }
    }

    // Add id (aditional to _id) for the Graph
    const filteredTermArrayNew = filteredTermArray.map(item => {
        return {
            ...item,
            id: item._id
        };
    });



    // Transform to Flow Chart data form.
    //const flowChartData = [];
    const nodes = [];
    const edges = [];
    filteredTermArrayNew.map(obj => {

        // Set Name
        var data = {}
        data.label = <GraphNode obj={obj} objColor={"white"} />
        obj.data = data;
        // Style for FlowChart Elements/Nodes (This replace the css)
        var style = {
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '3px',
            width: 'auto',
            border: '0px solid #ffffff',
            padding: '0px',
        }
        obj.style = style;
        //obj.className = 'circle'


        // Add position
        obj.position = defaultValues.position

        /////////////////////////////////////////////////////////////////
        //// NEW --> Muss noch weiter verbessert und getestet werden!!!!

        if (viewSet.showView) {

            if (termItem.views.length !== 0) {
                const viewId = termItem.views[0]
                let currView = {}
                //console.log(viewArray)
                if (viewArray) {
                    //console.log("currView")
                    viewArray.map((view) => {
                        if (view._id === viewId) {
                            currView = view
                            //console.log(currView.date)
                        }
                    })
                }

                for (let i = 0; i < currView.terms.length; i++) {
                    var myObject = eval('(' + currView.terms[i] + ')');
                    if (myObject.id === obj.id) {
                        obj.position = myObject.position
                        //console.log(obj.position)
                    }
                }
            }
        }

        //////////////////////////////////////////////////////////////////////



        nodes.push(obj)
        return nodes
    });

    // Add relations
    console.log("filteredRelations")
    console.log(filteredRelations)

    filteredRelations.map(obj => {

        // Main Syle definition in generateRelations.jsx

        const mId = Math.floor(Math.random() * 10000000)
        obj.linkId = obj.id
        obj.id = "e1-" + mId + "_" + termItem._id
        obj.arrowHeadType = "arrowclosed"
        obj.type = "floating" // default (bezier), straight, step and smoothstep.
        obj.idOfCenter2 = termItem._id

        //obj.style.stroke= '#f6ab6c'

        /*       var labelStyle = {      
                  fontWeight: 200,
                  fontSize: 20,
                  fill: '#f6ab6c',
                  orient: 'auto-start-reverse',
                  fontFamily:"Arial",
              }
              obj.labelStyle = labelStyle; */


        /*         obj.labelBgPadding= "[8, 4]"
                obj.labelBgBorderRadius= "4"
                obj.labelBgStyle= "{ fill: '#FFCC00', color: '#fff', fillOpacity: 0.7 }"
         */
        /* var markerEnd = {
            type: MarkerType.ArrowClosed,
            width: '20',
            height: '30',
            color: '#f6ab6c',
            orient: 'auto-start-reverse',
        }
        obj.markerEnd = markerEnd; */
        //console.log("obj")
        //console.log(obj)
        edges.push(obj)
        return edges
    });
    //console.log("flowChartData")
    //console.log(nodes)
    //console.log(edges)


    const flowChartData = {
        "nodes": nodes,
        "edges": edges
    }

    return (flowChartData)
}


