import { useContext, useEffect, useState, } from 'react';
import { Box, Button, Container, Fab, InputBase, List, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useScrollTrigger, Zoom } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Block, Edit } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';

// Context
import AuthContext from '../../context/authContext';
import { getUser, Signup } from '../../graphqlCommunication/AuthInterface';
import { SelectPermission } from './SelectPermission';
import { UpdateUserDialog } from './UpdateUserDialog';
import { DeleteTerm, UpdateTerm } from '../../graphqlCommunication/TermsInterface';
import { DeleteLink, UpdateLink } from '../../graphqlCommunication/LinksInterface';
import { CreateSetting, DeleteSetting, GetAllSettings, UpdateSetting } from '../../graphqlCommunication/SettingsInterface';
import { AddNewSettingDialog } from './AddNewSettingDialog';
import { RemeoveSettingDialog } from './RemoveSettingDialog';


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.myDivider.main,
        color: theme.palette.text,
        fontSize: "1rem",
    },
    body: {
        fontSize: "1rem",
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(8),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        background: "lightgray",
        minHeight: '100vh',
        maxWidth: "none",
    },
    paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
    input: {
        minWidth: 140,
        maxWidth: 180,
    },
    margin: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    text: {
        color: theme.palette.text.main,
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    button: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    buttonTableHead: {
        marginRight: theme.spacing(0),
        marginLeft: theme.spacing(1),
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.text.main,
    },
    fab: {
        background: "none",
        boxShadow: "none",
        color: theme.palette.primary.dark,
    },
    icon: {
        color: theme.palette.primary.dark,
    },
    table: {
        minWidth: 700,
    },

}));

const emptyForm = {
    name: '',
    email: '',
    password: '',
    permission: '',
}

// Settings Structure for testing
const sMap = [
    {
        _id: "0815",
        description: "definition of all Domains",
        name: "domain",
        parameters: [
            "{id_d04;All; This stands for all thinks whitch are alive}",
            "{123;Automotive;It is an Auto}",
            "{456;Bionic;This stands for all thinks whitch are alive}"
        ]
    },
    {
        _id: "0816",
        description: "definition of all Terms",
        name: "termType",
        parameters: [
            "{id_d04;All; This stands for all thinks whitch are alive}",
            "{123;Automotive;It is an Auto}",
            "{456;Bionic;This stands for all thinks whitch are alive}"
        ]
    },
    {
        _id: "0817",
        description: "definition of all Link",
        name: "linkType",
        parameters: [
            "{id_d04;All; This stands for all thinks whitch are alive}",
            "{123;Automotive;It is an Auto}",
            "{456;Bionic;This stands for all thinks whitch are alive}"
        ]
    },
]


export const ParameterSettingsTable = ({termArray,linkArray}) => {
    const classes = useStyles();

    // Context
    const contextType = useContext(AuthContext);
    const [settings, setSettings] = useState([])

    // Button Color when input Change TODO: Hier muss das noch für jeden Butten einzelnd eingestellt werden.
    const [flag, setFlag] = useState(true);

    // For Oben an Close the Dialog
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Get Settings form Database
    const handleGetSettings = () => {
        GetAllSettings(setSettings, contextType.token)
    }

    // Save Event Button for each row
    const handleButtonSave = (p0, p1, index, setIndex) => {
        //console.log("Save")
        setFlag(true) 

        // Get the existing ID
        let para = settings[setIndex].parameters[index]
        para = para.substring(1, para.length - 1)
        const paraSplit = para.split(";")
        const id = paraSplit[0]
        const name = paraSplit[1]

        // Make the new string to update the setting
        const tmpSet = settings
        const newPara = "{" + id + ";" + p0 + ";" + p1 + "}"
        tmpSet[setIndex].parameters[index] = newPara
        //console.log(newPara)
        setSettings(tmpSet)
        const SettingItem = tmpSet[setIndex]

        // Generate a date
        const d = new Date();
        let currDate = d.toISOString();
        SettingItem.date = currDate

        // Update the Settings
        //console.log(SettingItem)
        UpdateSetting(SettingItem, contextType.token)


        //console.log("linkArray")
        //console.log(linkArray)

        //console.log("termArray")
        //console.log(termArray)

        // Update all Terms with the new setting
        const setName = settings[setIndex].name
        if ("domain" === setName || "termType" === setName) {
            termArray.map((termItem) => {
                //console.log("Current:" + termItem.name + " | " + termItem.domain + " | " + termItem.type)

                // Update domain if the Domain will change
                if ("domain" === setName) {
                    termItem.domain.map((dom, index) => {
                        if (dom === name) {
                            dom = p0
                            termItem.domain[index] = dom
                            //console.log("Update:" + termItem.name + " | " + termItem.domain[index] + " | " + termItem.type)
                            UpdateTerm(termItem, contextType.token)
                            return termItem;
                        }
                    })
                }

                // Update the term type if this wimm change
                if ("termType" === setName) {
                    if (termItem.type === name) {
                        termItem.type = p0
                        //console.log("Update:" + termItem.name + " | " + termItem.domain + " | " + termItem.type)
                        UpdateTerm(termItem, contextType.token)
                        return termItem;
                    }
                }

            })
        }

        // Update all Links with the new setting
        if ("linkType" === setName) {
            //console.log("linkArray")
            //console.log(linkArray)
            linkArray.map((linkItem) => {
                //console.log("Current:" + linkItem.name + " | " + linkItem.type)
                // Update linkTypes if the linkType will change
                if ("linkType" === setName) {
                    if (linkItem.type === name) {
                        linkItem.type = p0
                        //console.log("Update:" + linkItem.name + " | " + linkItem.type)
                        UpdateLink(linkItem, contextType.token)
                        return linkItem;
                    }
                }
            })
        }
    }

    // Add a new Setting
    const handleButtonNew = (settingName, values) => {
        //console.log("Add new to:" + settingName)
        const newSet = settings.map((setting, index) => {
            if (setting.name === settingName) {
                let myuuid = uuidv4();
                const addSet = (pp) => [
                    ...pp,
                    "{" + myuuid + ";" + values.parameter + ";" + values.description + "}"
                ];
                setting.parameters = addSet(setting.parameters)

                // Add current date
                const d = new Date();
                let currDate = d.toISOString();
                setting.date = currDate

                // Update Settings in Database
                UpdateSetting(setting, contextType.token)
            }
            return setting
        })
        setSettings(newSet)
        //console.log(newSet)
    }

    // Remove Setting Item (Here will just delete an item of the Array)
    const handleButtonRemove = (p0, p1, index, setIndex) => {
        //console.log("Remove:" + setIndex + " " + index)
        const newSet = settings.map((setting, index2) => {
            if (index2 === setIndex) {
                const remSet = (pp) => {
                    return pp.filter((_, i) => i !== index)
                };
                setting.parameters = remSet(setting.parameters)

                // Add current date
                const d = new Date();
                let currDate = d.toISOString();
                setting.date = currDate

                // Update Settings in Database
                UpdateSetting(setting, contextType.token)

            }
            return setting
        })
        setSettings(newSet)
        //console.log(newSet)
    }

    // Generate Rows of each Table base on the setting name
    const tableRows = (settingName) => {

        const li = settings.map((setting, setIndex) => {
            //console.log("Administation -> Setting List")
            //console.log(setting)

            if (setting.name === settingName) {
                const li = setting.parameters.map((para, index) => {
                    //console.log("Para")
                    //console.log(para)
                    if (para.startsWith("{") && para.endsWith("}")) {

                        para = para.substring(1, para.length - 1)
                        const paraSplit = para.split(";")

                        // Edible Columbs
                        let p0 = paraSplit[1]
                        let p1 = paraSplit[2]
                        const handleChangeTabelItem = (event) => {
                            //console.log(event.target.name)
                            if (event.target.name === "parameter") {
                                p0 = event.target.value;
                            }
                            if (event.target.name === "description") {
                                p1 = event.target.value;
                            }
                            //setFlag(false)
                        };

                        //console.log(p0)
                        return (
                            <TableRow key={setting.name + "_" + p0 + "_" + p1 + "_" + Math.random()}>
                                <StyledTableCell component="th" scope="row">
                                    {/* <InputBase></InputBase> */}
                                    <TextField
                                        margin="dense"
                                        id="parameter"
                                        fullWidth
                                        defaultValue={paraSplit[1]}
                                        name="parameter"
                                        variant="outlined"
                                        onChange={handleChangeTabelItem}

                                    />
                                </StyledTableCell >
                                <StyledTableCell align="left">
                                    <TextField
                                        margin="dense"
                                        id="description"
                                        fullWidth
                                        defaultValue={paraSplit[2]}
                                        name="description"
                                        variant="outlined"
                                        onChange={handleChangeTabelItem}
                                    />
                                </StyledTableCell >
                                <StyledTableCell align="right" >
                                    <Button
                                        color={flag ? "primary" : "secondary"}
                                        onClick={event => handleButtonSave(p0, p1, index, setIndex)}
                                    >
                                        Save
                                    </Button>
                                    <RemeoveSettingDialog
                                        settingName={settingName}
                                        rowParas={[p0, p1, index, setIndex]}
                                        handleButtonRemove={handleButtonRemove} classes={classes}
                                        render={(open) => (
                                            <Button
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                className={classes.buttonTableHead}
                                                onClick={open}
                                            >
                                                Remove
                                            </Button>
                                        )}
                                    ></RemeoveSettingDialog>
                                </StyledTableCell >
                            </TableRow>
                        )
                    }
                })
                return li;
            }
        })
        return li;
    }

    // Table Conatiner for each Setting inklude New button
    const generateSettingTable = (settingName, settingLable) => {
        return (
            <>
                <Typography className={classes.text} variant="h6">{settingLable}</Typography>
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">Parameter</StyledTableCell>
                                <StyledTableCell align="left">Description</StyledTableCell>
                                <StyledTableCell align="right">
                                    <AddNewSettingDialog
                                        settingName={settingName}
                                        settings={settings}
                                        handleGetSettings={handleGetSettings}
                                        setSettings={setSettings}
                                        handleClose={handleClose}
                                        handleButtonNew={handleButtonNew}
                                        classes={classes}
                                        render={(open) => (
                                            <Button
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                className={classes.buttonTableHead}
                                                onClick={open}
                                                variant="contained"
                                                color="primary"
                                            >
                                                New
                                            </Button>
                                        )}
                                    ></AddNewSettingDialog>
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableRows(settingName)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }


    ///////////
    // Retun //
    ///////////

    return (
        <Paper className={classes.paper}>
            <Typography className={classes.text} variant="h5">Parameter Settings</Typography>
            {generateSettingTable("domain", "Domain")}
            {generateSettingTable("termType", "Term Types")}
            {generateSettingTable("linkType", "Link Types")}
            <Button variant="contained" color="primary" onClick={handleGetSettings} className={classes.button}>Get Settings</Button>
        </Paper>
    )
}
