import { useState, Fragment, useContext, useEffect } from 'react';
import AuthContext from '../../../context/authContext';
import DataContext from '../../../context/dataContext';

// Import Material 
import { Box, Button, makeStyles, TextField } from '@material-ui/core'
//import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { NameInput } from '../../addNewView/NameInput';
import { SelectLinkType } from '../../basicComponents/SelectLinkType';
//import { AutocompleteTerms } from '../../basicComponents/AutocompleteTerms';
import { DeleteLink, UpdateLink } from '../../../graphqlCommunication/LinksInterface';

//Import Components
/* import { UpdateTerm } from '../../graphqlCommunication/TermsInterface';
import { SelectTermType } from '../basicComponents/SelectTermType'; */


const useStyles = makeStyles((theme) => ({
    input: {
        minWidth: 100,
        maxWidth: 300,
    },
    margin: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    button: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    text: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        minWidth: 100,
        width: 396,
    }
}));

//export const EditLinkDialog = ({ linkItem, handleEditDialogClose, editDialogOpen, linkTypeMap, setLinkArray, setLinkItem, linkArray, termArray }) => {

export const EditLinkDialog = ({ handleEditDialogClose, editDialogOpen, linkTypeMap, linkItem }) => {

    const classes = useStyles();

    // Context
    const contextType = useContext(AuthContext);
    const dataContext = useContext(DataContext);
    const setLinkArray = dataContext.setLinkArray;
    const setTermItem = dataContext.setTermItem;
    //const linkItem = dataContext.linkItem;
    const setLinkItem = dataContext.setLinkItem;
    const linkArray = dataContext.linkArray;
    const termArray = dataContext.termArray;

    // Find Term by ID
    const toTerm = termArray.find(term => term._id === linkItem.toId);
    const fromTerm = termArray.find(term => term._id === linkItem.fromId);

    const [values, setValues] = useState(
        {
            name: linkItem.name,
            description: linkItem.description,
            type: linkItem.type
        }
    );

    // If linkItem will change the values will be set again
    useEffect(() => {
        setValues({
            name: linkItem.name,
            description: linkItem.description,
            type: linkItem.type
        })
    }, [linkItem]);

    // Create an Description 
    const handleMakeDescription = () => {
        if (values.description === "") {
            setValues({
                name: values.name,
                description: fromTerm.name + " " + values.type + " " + toTerm.name,
                type: values.type
            })
        }
    };

    const handleClose = () => {
        ////console.log("--- Close")
        handleEditDialogClose();
    };

    const handleDelete = () => {
        // Delete Link
        DeleteLink(linkItem._id, setLinkArray, linkArray, contextType.token)
        handleEditDialogClose();
        //console.log("DeleteLink wit Id:" + linkItem._id)
    };

    const handleSave = () => {
        handleClose();
        // Save data 
        //console.log(values)
        if (values.name !== undefined) {
            linkItem.name = values.name;
        }
        if (values.description !== undefined) {
            linkItem.description = values.description;
        }
        if (values.type !== undefined) {
            linkItem.type = values.type;
            linkItem.name = values.type;
        }
        // Cummunication with Backend
        UpdateLink(linkItem, contextType.token)
        setLinkItem(linkItem)// WARNING! if the Server connection went wrong the local term will change althought.
    };

    const handleChange = (event, newValue) => {
        let { value, name } = event.target;
        //console.log(values)
        setValues(values => {
            return {
                ...values,
                [name]: value
            };
        });
    };

    return (
        <Fragment>
            <Dialog open={editDialogOpen} onClose={handleEditDialogClose} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth="true">
                <DialogTitle id="form-dialog-title">Edit Link</DialogTitle>
                <DialogContent>
                    <Box>
                        <NameInput data={fromTerm} onChange={handleChange} classes={classes} labelName={"From"} />
                        <SelectLinkType
                            data={values}
                            onChange={handleChange}
                            classes={classes}
                            linkTypeMap={linkTypeMap}
                        />
                        <NameInput data={toTerm} onChange={handleChange} classes={classes} labelName={"To"} />
                        <Button className={classes.button} color="primary" onClick={handleMakeDescription}>
                            Make Description
                        </Button>
                        {
                            <TextField
                                value={values.description}
                                onChange={handleChange}
                                className={classes.text}
                                label={"Description"}
                                name={"description"}
                                variant="outlined"
                                multiline
                                maxRows={10} />
                        }
                    </Box>
                    {linkItem.creator &&
                        <DialogContentText>
                            {"id: " + linkItem._id + " | Creator: " + linkItem.creator.email}
                        </DialogContentText>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} color="secondary">
                        Delete
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} variant="contained" color="primary" >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}