import { CreateSetting } from "../graphqlCommunication/SettingsInterface";


export function loadInitalSettings(setSettingArray,token) {
    const d = new Date();
    let currDate = d.toISOString();
    const Settings = [
        { _id: "domain", date: currDate, name: "domain", description: "definition of all Domains", parameters: [
            "{id_d01;All;This stands for all Domains}", 
            "{id_d02;PLM;Product Lifecycle Management}", 
            "{id_d03;Automotive; This stands for all thinks with weels}",
            "{id_d04;Botanic; This stands for all thinks whitch are alive}",
            "{id_d05;Architecture; This stands for all thinks wich are build by Human and Hume live}"
        ] },
        { _id: "termType", date: currDate, name: "termType", description: "definition of all Term types", parameters: [
            "{id_tt01;General; This is for all terms}", 
            "{id_tt02;Standard; This stnad for all Standas e.g. DIN, ISO ...}", 
            "{id_tt03;Technology;}",
            "{id_tt04;Project;}"
        ] },
        { _id: "linkType", date: currDate, name: "linkType", description: "definition of all link types", parameters: [
            "{id_lt01;is part of; Thes link describes if this therm is an part of the other term}", 
            "{id_lt02;refines by; ...}", 
            "{id_lt03;is incuded; ...}"
        ] }
    ]

    // Create Settings at database
    Settings.map((settingItem)=> {
        CreateSetting(settingItem,setSettingArray,token)
    })

    //console.log("set initial Settings to:")
    //console.log(Settings)
    setSettingArray(Settings)
}

