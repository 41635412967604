import { makeStyles, Typography, alpha } from '@material-ui/core'
import { Home } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';

// Icons
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ImportExportIcon from '@material-ui/icons/ImportExport';
//import AccountTreeIcon from '@material-ui/icons/AccountTree';
//import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import { Settings } from '@material-ui/icons';

import AuthContext from '../../context/authContext';
import { useContext } from 'react';




const useStyles = makeStyles((theme) => ({
    item: {
        display: "flex",
        alignItems: "center",
        height: 36,
        cursor: "pointer",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.black, 0.15),
        },
    },
    icon: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(0),
        color: theme.palette.text.main,
        [theme.breakpoints.up("sm")]: {
            fontSize: "lage",
        },
    },
    text: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        color: theme.palette.text.main,
        /*[theme.breakpoints.down("sm")]: {
          display: "none",
        }*/
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.text.primary
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}));

// Bart Intems for Navigation
export const BarItems = () => {
    const classes = useStyles();
    // Context
    const contextType = useContext(AuthContext);

    return (
        <>
            <Link to="/" className={classes.link}>
                <div className={classes.item}>
                    <Home className={classes.icon}></Home>
                    <Typography className={classes.text}>Navigation</Typography>
                </div>
            </Link>

            {/* <Link to="/graph" className={classes.link}>
                <div className={classes.item}>
                    <AccountTreeIcon className={classes.icon}></AccountTreeIcon>
                    <Typography className={classes.text}>Graph</Typography>
                </div>
            </Link>

            <Link to="/forceGraph" className={classes.link}>
                <div className={classes.item}>
                    <ScatterPlotIcon className={classes.icon}></ScatterPlotIcon>
                    <Typography className={classes.text}>Force Graph</Typography>
                </div>
            </Link> */}

            {contextType.permission !== "read" && (
                <Link to="/edit" className={classes.link}>
                    <div className={classes.item}>
                        <AddCircleOutlineIcon className={classes.icon}></AddCircleOutlineIcon>
                        <Typography className={classes.text}>Add New</Typography>
                    </div>
                </Link>
            )}

            {contextType.permission !== "read" && (
                <Link to="/linkAnalysis" className={classes.link}>
                    <div className={classes.item}>
                        <SettingsEthernetIcon className={classes.icon}></SettingsEthernetIcon>
                        <Typography className={classes.text}>Link Analysis</Typography>
                    </div>
                </Link>
            )}

            {contextType.permission !== "read" && (
                <Link to="/import" className={classes.link}>
                    <div className={classes.item}>
                        <ImportExportIcon className={classes.icon}></ImportExportIcon>
                        <Typography className={classes.text}>Import</Typography>
                    </div>
                </Link>
            )}

            {contextType.permission === "admin" && (
                <>
                    <Divider className={classes.divider} />
                    <Link to="/Administration" className={classes.link}>
                        <div className={classes.item}>
                            <Settings className={classes.icon}></Settings>
                            <Typography className={classes.text}>Administration</Typography>
                        </div>
                    </Link>
                </>
            )}
        </>
    )
};