//const BACKEND_ADRESS = 'http://localhost:8000/graphql'
//const BACKEND_ADRESS = 'http://graphqltuorial-env.eba-bmibwfqm.us-east-2.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'http://limabackend.eba-bmibwfqm.us-east-2.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'http://limanticbackend-env.eba-ktayy22j.eu-north-1.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'https://backend.app.limantic.com/graphql'

// Umgebungsvariablen
// Diese Funktionieren nur mit dem consolen aufruf: npm start .env
// diese werden in der Entwicklungsumgebung im Projekt im file .env gepflegt
// Beschrieben auf: https://create-react-app.dev/docs/adding-custom-environment-variables/
const BACKEND_ADRESS = process.env.REACT_APP_BACKEND_ADRESS;
//test


// Create new Group //
export const CreateGroup = (name, settingId, members, contextType) => {
    if (name.trim().length === 0 || name.trim().length === 0) {
        return;
    }

    // Query CreateGroup
    let requestBody = {
        query: `
                mutation CreateGroup($name: String!, $settingId: ID!, $members: String!) {
                    createGroup(groupInput: {name: $name, settingId: $settingId, members: $members }) {
                        _id
                        name
                        settingId
                        members
                    }
                }
            `,
        variables: {
            name: name,
            settingId: settingId,
            members: members
        }
    }

    // Communication to Backend
    Communication(requestBody, contextType)
}

// Update existing Group //
export const UpdateGroup = (groupId, name, members, terms, contextType) => {
    if (name.trim().length === 0 || name.trim().length === 0) {
        return;
    }

    // Query CreateGroup
    let requestBody = {
        query: `
                mutation UpdateGroup($name: String!, $members: String!, $terms: String!,$groupId: ID!) {
                    updateGroup(groupId:$groupId, groupInput: {name: $name, members: $members, terms: $terms }) {
                        _id
                        name
                        permission
                    }
                }
            `,
        variables: {
            groupId: groupId,
            name: name,
            members: members,
            terms: terms
        }
    }

    // Communication to Backend
    Communication(requestBody, contextType)
}


// Communication with backend
const Communication = (requestBody, contextType) => {

    ////console.log(JSON.stringify(requestBody))
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + contextType.token,
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        if (resData.data.login.token) {
            contextType.login(
                resData.data.login.token,
                resData.data.login.groupId,
                resData.data.login.tokenExpiration,
                resData.data.login.name
            )
        }
        //console.log(resData)
    }).catch(err => {
        //console.log(err)
    });
}


// Get all Group //
export const getGroup = (setGroups) => {

    // Queries Login 
    let requestBody = {
        query: `
            query {
                groups{
                    _id
                    name
                    settingId
                    members
                }
            }
         `
    }

    // Communication to Backend
    ////console.log(JSON.stringify(requestBody))
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        setGroups(resData.data.groups)
        //console.log(resData)
    }).catch(err => {
        //console.log(err)
    });

}


// Get Group by id //
export const GetGroupById = (groupId, setGroup) => {

    // Query groupById
    let requestBody = {
        query: `
                mutation groupById($groupId: ID!) {
                    groupById(groupId:$groupId) {
                        _id
                        name
                        settingId
                        members
                    }
                }
            `,
        variables: {
            groupId: groupId
        }
    }

    // Communication to Backend
    ////console.log(JSON.stringify(requestBody))
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        setGroup(resData.data.groupById)
        //console.log(resData)
    }).catch(err => {
        //console.log(err)
    });

}

// Delete a Group! Warning!!! 
export const deleteGroup = (groupId) => {

    if (groupId.trim().groupId === 0) {
        return;
    }

    // Query CreateGroup
    let requestBody = {
        query: `
                mutation DeleteGroup($groupId: ID!) {
                    deleteGroup(groupId: $groupId) {
                        _id
                        name
                    }
                }
            `,
        variables: {
            groupId: groupId,
        }
    }

    // Communication to Backend
    ////console.log(JSON.stringify(requestBody))
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        //console.log(resData)
    }).catch(err => {
        //console.log(err)
    });

}
