import { useContext, useState } from 'react';
import { Button, TextField, makeStyles, Paper, Typography, FormControl, InputLabel, InputAdornment, IconButton, OutlinedInput } from '@material-ui/core';
import clsx from 'clsx';

// Logos
import { ReactComponent as Logo } from '../logo.svg';
import MeshImage from '../mesh.png';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AuthContext from '../context/authContext';
import { Login } from '../graphqlCommunication/AuthInterface';


// Style
const useStyles = makeStyles((theme) => ({
    login: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        background: "lightgray",
        height: "100vh",
        backgroundImage: `url(${MeshImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
    },
    input: {
        minWidth: 150,
        maxWidth: 200,
        background: "white",
    },
    margin: {
        margin: theme.spacing(1),

    },
    paper: {
        margin: theme.spacing(4),
        position: 200,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
    },
    logo: {
        width: 200,
        height: 60,
    }
}));


export const LoginPage = ({error }) => {
    const classes = useStyles();
    const contextType = useContext(AuthContext);

    // UseState Constances
    const [values, setValues] = useState({
        user:'',
        password: '',
        showPassword: false,
    });
   

    // Functions
    const handleSubmit = () => {
        //const email = "text@test.de"
        //const password = "test"
        Login(values.user, values.password,contextType)
    }

    const handleChangePassWord = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // Return
    return (
        <div className={classes.login}>
            <div className={classes.logo}>
                <Typography variant="h1">
                    <Logo className={classes.logo} />
                </Typography>
            </div>
            <Paper className={classes.paper}>
                <TextField
                    name="user"
                    value={values.user}
                    onChange={handleChangePassWord('user')}
                    id="standard-basic"
                    label="User"
                    className={clsx(classes.input, classes.margin)}
                    variant="outlined"
                    size="small"
                />
                <FormControl className={clsx( classes.margin)} variant="outlined" size="small">
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                        className={clsx(classes.input)}
                        id="outlined-adornment-password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChangePassWord('password')}
                        endAdornment={                            
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        labelWidth={70}
                    />
                </FormControl>
                <Button variant="contained" color="primary" className={classes.margin} onClick={handleSubmit}>Login</Button>
            </Paper>
        </div>
    );
}