import { Container, makeStyles } from '@material-ui/core'
import { BarItems } from './BarItems';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    /* height: "100vh", */
    position: "sticky",
    top: 0,
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "white",
     /*  border: "1px solid #999" */
    },
  }
}));


export const Leftbar = () => {
  const classes = useStyles();

  // Return
  return (
    <Container className={classes.container}>
        <BarItems/>
    </Container>
  );
}

