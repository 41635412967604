import React from 'react';
import clsx from 'clsx';
import {TextField } from '@material-ui/core';
import { Fragment } from 'react';

export const NameInput = ({data,onChange,classes, label = "Name", name = "name"}) => {

    return(  
        <Fragment>            
            <TextField 
                name = {name}
                value = {data.name}
                onChange={ event => onChange(event,data.name) }
                id="standard-basic" 
                label={label} 
                className={clsx (classes.input, classes.margin)}
                variant="outlined"
                size="small"         
            />
        </Fragment>
    );

}