import React, { useCallback, useContext, } from 'react';
import { Autocomplete } from "@material-ui/lab";
import { TextField } from '@material-ui/core';
import DataContext from '../../context/dataContext';


export const AutocompleteTerms = ({setTerm, classes }) => {

    // Use Context
    const dataContext = useContext(DataContext);
    const termArray = dataContext.termArray;
    const termItem = dataContext.termItem;

    // Select Term (Autocomplete)
    const handleAutocmpleteSelection = useCallback((event, newValue) => {
        if (newValue !== "" && newValue !== null) {
            const id = newValue._id
            // Look for fitting ID  
            termArray.map(obj => {
                if (obj._id === id) {
                    setTerm(obj) 
                    return obj;
                }
                return obj; 
            });
            //console.log(termItem)
        }
    }, [termItem,setTerm,termArray]);

    return (
        <Autocomplete
            className={classes.input}
            id="combo-box-demo"
            size="small"
            options={termArray}
            getOptionLabel={(termArray) => { return (termArray.name + " (id:" + termArray._id + ")") }}
            renderInput={(params) => <TextField {...params} label="Select Term" variant="outlined" />}
            onChange={(event, newValue) => { handleAutocmpleteSelection(event, newValue); }}
        />
    )

}