//const BACKEND_ADRESS = 'http://localhost:8000/graphql'
//const BACKEND_ADRESS = 'http://graphqltuorial-env.eba-bmibwfqm.us-east-2.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'http://limabackend.eba-bmibwfqm.us-east-2.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'http://limanticbackend-env.eba-ktayy22j.eu-north-1.elasticbeanstalk.com/graphql'
//const BACKEND_ADRESS = 'https://backend.app.limantic.com/graphql'

// Umgebungsvariablen
// Diese Funktionieren nur mit dem consolen aufruf: npm start .env 
// diese werden in der Entwicklungsumgebung im Projekt im file .env gepflegt
// Beschrieben auf: https://create-react-app.dev/docs/adding-custom-environment-variables/
const BACKEND_ADRESS = process.env.REACT_APP_BACKEND_ADRESS; 



// Get all Items
export const GetAllExternalLinks = (setExternalLinkArray) => {

    // Query
    const requestBody = {
        query: `
                query {
                    externalLinks{
                        _id
                        description
                        date   
                        type                 
                    }
                }
            `
    }

    // Communication with Backend
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        const externalLinks = resData.data.externalLinks;
        setExternalLinkArray(externalLinks)
        //console.log(externalLinks)
    }).catch(err => {
        //console.log(err)
    });
}

////////////////////////////////
// Delete externalLinks by ID //
export const DeleteExternalLink = (externalLinkId, setExternalLinkArray, externalLinks, token) => {

    // Query
    const requestBody = {
        query: `
                mutation DeleteExternalLink($id: ID!){
                    deleteExternalLink(externalLinkId:$id){
                        _id
                    }
                }
            `,
        variables: {
            id: externalLinkId
        }
    }

    // Communication with Backend    
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        // Update ExternalLinks
        const updateExternalLink = externalLinks.filter(externalLink => {
            return externalLink._id !== externalLinkId;
        })
        setExternalLinkArray(updateExternalLink)
    }).catch(err => {
        //console.log(err)
    });
}

///////////////////////
// Update ExternalLink by ID //
export const UpdateExternalLink = (externalLinkItem, token) => {

    //externalLinkItem.abbreviation = "test"//externalLinkItem.abbreviation;

    // Queries Login or CreateUser
    const requestBody = {
        query: `
                    mutation UpdateExternalLink($externalLinkId: ID!, $url: String!, $description: String!,$date: String!, $type: String!){
                        updateExternalLink(externalLinkId: $externalLinkId  externalLinkInput: {url: $url, description: $description, date: $date, type: $type}) {
                            _id
                            url
                            description
                            date
                            type                           
                        }
                    }
                `,
        variables: {
            url: externalLinkItem.url,         
            date: externalLinkItem.date,
            description: externalLinkItem.description,
            externalLinkId: externalLinkItem._id,
            type: externalLinkItem.type,
        }
    }


    // Functuion to update
    const handelResData = (resData) => {
        //setExternalLinkItem(externalLinkItem)
    }

    // Communicate with Backend
    Communication(requestBody, handelResData, token);
}

/////////////////
// Create ExternalLink // 
export const CreateExternalLink = (externalLinkItem, setExLiId, exLiId, token) => {

    // Queries Login or CreateUser
    const requestBody = {
        query: `
                mutation CreateExternalLink( $url: String!, $description: String!, $date: String!, $type: String!){
                    createExternalLink(externalLinkInput: {url: $url, description: $description, date: $date, type: $type}) {
                        _id
                        url            
                        description
                        type                       
                    }
                }
            `,
        variables: {
            url: externalLinkItem.url,
            date: externalLinkItem.date,
            description: externalLinkItem.description,
            type: externalLinkItem.type,
        }
    }

    // Functuion to Add new ExternalLink to ExternalLinkArray
    const handelResData = (resData) => {
        if (resData.data.createExternalLink) {
            //console.log("Set new ExternalLink to list");
            //console.log(resData.data.createExternalLink._id);
            exLiId=resData.data.createExternalLink._id
            setExLiId(resData.data.createExternalLink._id)
            return (resData.data.createExternalLink._id)
           /*  setExternalLinkArray(prevState => {
                return [
                    ...prevState,
                    {
                        ...resData.data.createExternalLink
                    }
                ];
            }); */
        }
    }

    // Communicate with Backend
    const res = Communication(requestBody, handelResData, token);
    return res

}

///////////////////
// Communication //
export const Communication = (requestBody, handelResData, token) => {

    // Communication with Backend
    //console.log(JSON.stringify(requestBody))
    fetch(BACKEND_ADRESS, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    }).then(res => {
        if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed')
        }
        return res.json();
    }).then(resData => {
        const res = handelResData(resData);
        return res
    }).catch(err => {
        //console.log(err)
        return err;
    });
}
