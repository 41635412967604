import { useContext, useEffect, useState, } from 'react';
import { Box, Button, Container, Fab, InputBase, List, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useScrollTrigger, Zoom } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Block, Edit } from '@material-ui/icons';

// Context
import AuthContext from '../../context/authContext';
import { getUser, Signup } from '../../graphqlCommunication/AuthInterface';
import { SelectPermission } from './SelectPermission';
import { UpdateUserDialog } from './UpdateUserDialog';
import { DeleteTerm, UpdateTerm } from '../../graphqlCommunication/TermsInterface';
import { DeleteLink, UpdateLink } from '../../graphqlCommunication/LinksInterface';
import { CreateSetting, DeleteSetting, GetAllSettings, UpdateSetting } from '../../graphqlCommunication/SettingsInterface';
import { AddNewSettingDialog } from './AddNewSettingDialog';
import { RemeoveSettingDialog } from './RemoveSettingDialog';
import { ParameterSettingsTable } from './ParameterSettingsTable';


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.myDivider.main,
        color: theme.palette.text,
        fontSize: "1rem",
    },
    body: {
        fontSize: "1rem",
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(8),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        background: "lightgray",
        minHeight: '100vh',
        maxWidth: "none",
    },
    paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
    input: {
        minWidth: 140,
        maxWidth: 180,
    },
    margin: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    text: {
        color: theme.palette.text.main,
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    button: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    buttonTableHead: {
        marginRight: theme.spacing(0),
        marginLeft: theme.spacing(1),
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.text.main,
    },
    fab: {
        background: "none",
        boxShadow: "none",
        color: theme.palette.primary.dark,
    },
    icon: {
        color: theme.palette.primary.dark,
    },
    table: {
        minWidth: 700,
    },

}));

const emptyForm = {
    name: '',
    email: '',
    password: '',
    permission: '',
}

/* function ScrollTop(props) {
    const { children, window } = props;
    const classes = useStyles();
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <Zoom in={trigger}>
            <div onClick={handleClick} role="presentation" className={classes.root}>
                {children}
            </div>
        </Zoom>
    );
} */

export const Administration = ({ termArray, setTermArray, terms, linkArray, setLinkArray }) => {
    const classes = useStyles();

    // Context
    const contextType = useContext(AuthContext);

    const [data, setData] = useState(emptyForm);
    const [users, setUsers] = useState([]);
    const [settings, setSettings] = useState([]);
    //const [user, setUser] = useState([]);
    const [deleteCode, setDeleteCode] = useState("000");
    //const [formSubmitted, setFormSubmitted] = useState(false);

    // 
    const handleGetSettings = () => {
        GetAllSettings(setSettings, contextType.token)
    }

    // For Oben an Close the Dialog
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClose = () => {
        handleGetSettings()
        setAnchorEl(null);
    };


    const handleChange = (event, newValue) => {
        let { value, name } = event.target;
        setData(data => {
            return {
                ...data,
                [name]: value
            };
        });
    };

    const handleSubmit = () => {
        const { email, password, permission } = data;
        //console.log(data)
        if (email !== "" && password !== "" && permission !== "") {
            //setFormSubmitted(true);
            // Backend Communiactaion
            Signup(email, password, permission, contextType.token)
        } else {
            //console.log("New User has not been created: Wrong Input");
        }
    }

    const handleGetUsers = () => {
        getUser(setUsers)
    }


    // Update All Terms (Sollte immer verwednet werden wenn neue Attribute hinzugfügt werden)
    const handleUpdateAllTerms = () => {
        termArray.map((termItem) => {
            //console.log("Update:" + termItem.name)
            UpdateTerm(termItem, contextType.token)
            return termItem;
        })
    }


    const handleResetAbbreviation = () => {
        if (deleteCode === "4242") {
            termArray.map((termItem) => {
                //console.log("Update:" + termItem.name)
                termItem.abbreviation = ""
                UpdateTerm(termItem, contextType.token)
                return termItem;
            })
        }
    }

    const handleRemoveAllTerms = () => {
        if (deleteCode === "4242") {
            termArray.map((termItem) => {
                //console.log("Delete:" + termItem.name)
                DeleteTerm(termItem._id, setTermArray, termArray, contextType.token)
                return termItem;
            })
        } else {
            //console.log("Wrong delete code ")
        }
    }

    const handleRemoveAllAutoLinks = () => {
        //console.log("# handleRemoveAllAutoLinks #")
        if (deleteCode === "4242") {
            linkArray.map((linkItem) => {
                if (linkItem.description === "autoLink") {
                    //console.log("Delete:" + linkItem.name)
                    DeleteLink(linkItem._id, setLinkArray, linkArray, contextType.token)
                }
                return linkItem;
            })
        } else {
            //console.log("Wrong delete code ")
        }
    }

    const handleRemoveAllLinks = () => {
        //console.log("# handleRemoveAllLinks #")
        if (deleteCode === "4242") {
            linkArray.map((linkItem) => {
                //console.log("Delete:" + linkItem.name)
                DeleteLink(linkItem._id, setLinkArray, linkArray, contextType.token)
                return linkItem;
            })
        } else {
            //console.log("Wrong delete code ")
        }
    }

    const handleChangeDeleteCode = (event, newValue) => {
        let { value } = event.target;
        setDeleteCode(value);
    };



    // Map all User into the list Items
    const userList = users.map((val, index) => {
        //const key = `listItems-${index}`;
        return (
            <ListItem  >

                <ListItemText className={classes.listItem} primary={val.email} secondary={"Permission: " + val.permission} />
                {val.permission === "inactive" && (
                    < Block color="primary" />
                )}
                <span className={classes.listItem} >
                    <UpdateUserDialog user={val} handleClose={handleClose} classes={classes}
                        render={(open) => (
                            <Fab
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                className={classes.fab}
                                onClick={open}
                                size="small"                            >
                                <Edit />
                            </Fab>
                        )}
                    ></UpdateUserDialog>
                </span>
            </ListItem>
        )
    });


    // ##########################
    // ### Parameter Settings ###
    // ##########################




    // Save Event Button for each row
    const handleButtonSave = (p0, p1, index, setIndex) => {

        // Get the existing ID
        let para = settings[setIndex].parameters[index]
        para = para.substring(1, para.length - 1)
        const paraSplit = para.split(";")
        const id = paraSplit[0]
        const name = paraSplit[1]

        // Make the new string to update the setting
        const tmpSet = settings
        const newPara = "{" + id + ";" + p0 + ";" + p1 + "}"
        tmpSet[setIndex].parameters[index] = newPara
        //console.log(newPara)
        setSettings(tmpSet)
        const SettingItem = tmpSet[setIndex]

        // Generate a date
        const d = new Date();
        let currDate = d.toISOString();
        SettingItem.date = currDate

        // Update the Settings
        //console.log(SettingItem)
        UpdateSetting(SettingItem, contextType.token)

        // Update all Terms with the new setting
        const setName = settings[setIndex].name
        if ("domain" === setName || "termType" === setName) {
            termArray.map((termItem) => {
                //console.log("Current:" + termItem.name + " | " + termItem.domain + " | " + termItem.type)

                // Update domain if the Domain will change
                if ("domain" === setName) {
                    termItem.domain.map((dom, index) => {
                        if (dom === name) {
                            dom = p0
                            termItem.domain[index] = dom
                            //console.log("Update:" + termItem.name + " | " + termItem.domain[index] + " | " + termItem.type)
                            UpdateTerm(termItem, contextType.token)
                            return termItem;
                        }
                    })
                }

                // Update the term type if this wimm change
                if ("termType" === setName) {
                    if (termItem.type === name) {
                        termItem.type = p0
                        //console.log("Update:" + termItem.name + " | " + termItem.domain + " | " + termItem.type)
                        UpdateTerm(termItem, contextType.token)
                        return termItem;
                    }
                }

            })
        }

        // Update all Links with the new setting
        if ("linkType" === setName) {
            linkArray.map((linkItem) => {
                //console.log("Current:" + linkItem.name + " | " + linkItem.type)
                // Update linkTypes if the linkType will change
                if ("linkType" === setName) {
                    if (linkItem.type === name) {
                        linkItem.type = p0
                        //console.log("Update:" + linkItem.name + " | " + linkItem.type)
                        UpdateLink(linkItem, contextType.token)
                        return linkItem;
                    }
                }
            })
        }
    }

    // Remove Setting Item (Here weill just delete an item of the Array)
    const handleButtonRemove = (p0, p1, index, setIndex) => {
        // Remove the Settings

        //console.log("#### DEBUG Remove ####")
        //console.log(p0, p1, index, setIndex)

        // Get the existing ID
        let para = settings[setIndex].parameters[index]
        para = para.substring(1, para.length - 1)
        const paraSplit = para.split(";")
        const id = paraSplit[0]
        const name = paraSplit[1]

        // Make the new string to update the setting
        const tmpSet = settings
        //console.log("tmpSet")
        //console.log(tmpSet)
        let parameters = tmpSet[setIndex].parameters

        const parameters2 = parameters.filter(function (item, i) {
            //console.log(item + "  " + index + " " + i)
            if (i !== index) {
                return item
            }
        })

        tmpSet[setIndex].parameters = parameters2
    
        

        const SettingItem = tmpSet[setIndex]

        // Generate a date
        const d = new Date();
        let currDate = d.toISOString();
        SettingItem.date = currDate

        // Update the Settings
        UpdateSetting(SettingItem, contextType.token)

        setSettings([])
        setSettings(tmpSet)

        handleGetSettings()

        /*  // Update all Links with the new setting
         const setName = settings[setIndex].name
         if ("linkType" === setName) {
             linkArray.map((linkItem) => {
                 //console.log("Current:" + linkItem.name + " | " + linkItem.type)
                 // Update linkTypes if the linkType will change
                 if ("linkType" === setName) {
                     if (linkItem.type === name) {
                         linkItem.type = "xxx"
                         //console.log("Update:" + linkItem.name + " | " + linkItem.type)
                         UpdateLink(linkItem, contextType.token)
                         return linkItem;
                     }
                 }
             })
         } */
    }

    // Rows of the Table
    const tableRows = (settingName) => {
        const li = settings.map((setting, setIndex) => {
            //console.log("Administation -> Setting List")
            //console.log(setting)
            //console.log(Math.random())
            
            if (setting.name === settingName) {
                const li = setting.parameters.map((para, index) => {
                    if (para.startsWith("{") && para.endsWith("}")) {
                        //console.log("Para")
                        
                        para = para.substring(1, para.length - 1)
                        const paraSplit = para.split(";")

                        // Edible Columbs
                        let p0 = paraSplit[1]
                        let p1 = paraSplit[2]
                        const handleChangeTabelItem = (event) => {
                            //console.log(event.target.name)
                            if (event.target.name === "parameter") {
                                p0 = event.target.value;
                            }
                            if (event.target.name === "description") {
                                p1 = event.target.value;
                            }
                        };

                        //console.log(p0)
                        return (
                            <TableRow key={setting.name+"_"+p0+"_"+p1+"_"+Math.random()}>
                                <StyledTableCell component="th" scope="row">
                                    {/* <InputBase></InputBase> */}
                                    <TextField
                                        margin="dense"
                                        id="parameter"
                                        fullWidth
                                        defaultValue={paraSplit[1]}
                                        name="parameter"
                                        variant="outlined"
                                        onChange={handleChangeTabelItem}

                                    />
                                </StyledTableCell >
                                <StyledTableCell align="left">
                                    <TextField
                                        margin="dense"
                                        id="description"
                                        fullWidth
                                        defaultValue={paraSplit[2]}
                                        name="description"
                                        variant="outlined"
                                        onChange={handleChangeTabelItem}
                                    />
                                </StyledTableCell >
                                <StyledTableCell align="right" >
                                    <Button onClick={event => handleButtonSave(p0, p1, index, setIndex)}>Save</Button>
                                    {/* <Button onClick={event => handleButtonRemove(p0, p1, index, setIndex)}>Remove</Button> */}
 
                                    <RemeoveSettingDialog settingName={settingName} rowParas={[p0, p1, index, setIndex]} handleButtonRemove={handleButtonRemove} classes={classes}
                                        render={(open) => (
                                            <Button
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                className={classes.buttonTableHead}
                                                onClick={open}
                                            >
                                                Remove
                                            </Button>
                                        )}
                                    ></RemeoveSettingDialog>
                                </StyledTableCell >
                            </TableRow>
                        )
                    }
                   
                })
                return li;
            }
        })
        return li;
    }

    // Table Conatiner 
/*     const generateSettingTable = (settingName, settingLable) => {
        return (
            <>
                <Typography className={classes.text} variant="h6">{settingLable}</Typography>
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">Parameter</StyledTableCell>
                                <StyledTableCell align="left">Description</StyledTableCell>
                                <StyledTableCell align="right">
                                    <AddNewSettingDialog settingName={settingName} settings={settings} handleGetSettings={handleGetSettings} setSettings={setSettings} handleClose={handleClose} classes={classes}
                                        render={(open) => (
                                            <Button
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                className={classes.buttonTableHead}
                                                onClick={open}
                                                variant="contained"
                                                color="primary"
                                            >
                                                New
                                            </Button>
                                        )}
                                    ></AddNewSettingDialog>

                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableRows(settingName)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }
 */



    ///////////
    // Retun //
    ///////////

    return (
        <Container className={classes.container}>
            <Paper className={classes.paper}>
                <Typography className={classes.text} variant="h6">Create a new User</Typography>
                <div>
                    <TextField
                        name="email"
                        value={data.email}
                        onChange={event => handleChange(event, data.email)}
                        id="standard-basic"
                        label="E-Mail"
                        className={clsx(classes.input, classes.margin)}
                        variant="outlined"
                        size="small"
                    />
                    <TextField
                        name="password"
                        value={data.password}
                        onChange={event => handleChange(event, data.password)}
                        id="standard-basic"
                        label="Password"
                        className={clsx(classes.input, classes.margin)}
                        variant="outlined"
                        size="small"
                    />
                    <SelectPermission data={data} onChange={handleChange} classes={classes} />
                </div>
                <div>
                    <Button variant="contained" color="primary" onClick={handleSubmit} className={classes.button}>Add User</Button>
                </div>
            </Paper>
            <Paper className={classes.paper}>
                <Typography className={classes.text} variant="h6">Current User</Typography>

                <Box className={classes.list}>
                    <List>{userList}</List>
                </Box>
                <Button variant="contained" color="primary" onClick={handleGetUsers} className={classes.button}>Get Users</Button>
            </Paper>
            {/* <Paper className={classes.paper}>
                <Typography className={classes.text} variant="h5">Parameter Settings</Typography>
                {generateSettingTable("domain", "Domain")}
                {generateSettingTable("termType", "Term Types")}
                {generateSettingTable("linkType", "Link Types")}
                <Button variant="contained" color="primary" onClick={handleGetSettings} className={classes.button}>Get Settings</Button>
            </Paper> */}
            <Paper>
                <ParameterSettingsTable termArray={termArray} linkArray={linkArray}>
                </ParameterSettingsTable>
            </Paper>
            <Paper className={classes.paper}>
                <Typography className={classes.text} variant="h6">Database Settings</Typography>
                <Button variant="contained" color="primary" onClick={handleUpdateAllTerms} className={classes.button}>Update All Terms</Button>
                <Button variant="contained" color="primary" onClick={handleResetAbbreviation} className={classes.button}>Reset All Abbs</Button>
                <Button variant="contained" color="red" onClick={handleRemoveAllTerms} className={classes.button}>Delete All Terms</Button>
                <Button variant="contained" color="red" onClick={handleRemoveAllLinks} className={classes.button}>Delete All Links</Button>
                <Button variant="contained" color="red" onClick={handleRemoveAllAutoLinks} className={classes.button}>Delete All Auto Links</Button>
                <TextField
                    name="delete"
                    value={deleteCode}
                    onChange={event => handleChangeDeleteCode(event, deleteCode)}
                    id="standard-basic"
                    label="Insert delete code"
                    className={clsx(classes.input, classes.margin)}
                    variant="outlined"
                    size="small"
                />
            </Paper>
        </Container>
    )

}
