import { makeStyles, Container, Paper, ListItem, ListItemText, Grid, TextField, Divider, Box, List, Fab, Checkbox, FormControlLabel, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Tooltip } from '@material-ui/core'
import { useContext, useEffect, useState } from 'react';
//import { blueGrey, green, grey, orange, red, yellow } from '@material-ui/core/colors';


import DataContext from '../../context/dataContext';
import AuthContext from '../../context/authContext';
import { CreateTerm, GetAllTerms } from '../../graphqlCommunication/TermsInterface';
import parse from 'html-react-parser'

// Icons
import AddIcon from '@material-ui/icons/Add';
//import Update from '@material-ui/icons/Update';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Link } from 'react-router-dom';
import { TypeIcons } from './TypeIcons';
import { generateRelations, IncludeRelationsToDatabase } from '../visualisation/generateRelations';
import { DeleteLink } from '../../graphqlCommunication/LinksInterface';
import { Visibility } from '@material-ui/icons';
import ClearIcon from '@material-ui/icons/Clear';


// Syles
const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(9),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        background: "lightgray",
        height: "100vh",
        maxWidth: "none",
    },
    paper: {
        height: `calc(100% - ${theme.spacing(4)}px)`,
        padding: theme.spacing(2),
    },
    list: {
        height: `calc(100% - ${theme.spacing(8)}px)`,
        overflow: 'auto',
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(-2),
    },
    listItem: {
        color: theme.palette.primary.light,
        /*  marginTop: theme.spacing(0),
         marginBottom: theme.spacing(0),
         paddingTop: theme.spacing(1),
         paddingBottom: theme.spacing(1), */
    },
    listTextItem: {
        color: theme.palette.primary.light,
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
    },
    dividerTop: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(-2),
    },
    dividerBottom: {
        marginTop: theme.spacing(0),
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(-2),
    },
    textField: {
        height: "20"
    },
    fab: {
        color: theme.palette.secondary.contrastText,
        background: theme.palette.secondary.main,
        marginLeft: theme.spacing(1),
    },
    avatar: {
        marginRight: theme.spacing(2),
        color: '#fff',
        backgroundColor: theme.palette.secondary.dark,
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    checkbox: {
        marginLeft: theme.spacing(1),
    }
}));

// Main Function
export const TermTable = ({ setDawerState, setReloadContetnt }) => {
    const classes = useStyles();

    // Use Context
    const contextType = useContext(AuthContext);
    const dataContext = useContext(DataContext);
    const token = contextType.token;
    const termArray = dataContext.termArray;
    //const termItem = dataContext.termItem;
    const setTermArray = dataContext.setTermArray;
    const setTermItem = dataContext.setTermItem;
    const linkArray = dataContext.linkArray;
    const setLinkArray = dataContext.setLinkArray;

    // UseState Constances
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [showType, setShowType] = useState(true);


    // Component Functions
    const handleChange = e => {
        setSearchTerm(e.target.value);
    };

    const handleResetSearch = e => {
        setSearchTerm("");
    };

    const handleShowType = e => {
        setShowType(!showType)
    }


    // Sort Alphabetic
    function compare(a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }


    useEffect(() => {
        const results = termArray.filter(val =>
            val.name.toLowerCase().includes(searchTerm.toLowerCase()) || val.abbreviation.toLowerCase().includes(searchTerm.toLowerCase())
        );
        results.sort(compare);
        setSearchResults(results);
    }, [searchTerm, termArray]);

    // Update when termArray is change
    useEffect(() => {
        //updateLinkHandle()
    }, [searchTerm, termArray]);

    // Highlighting current selection
    const [itemSelected, setItemSelected] = useState();
    const updateSelected = (index) => {
        setItemSelected(index)
    };


    /* console.log("termArray")
    termArray.map((val) =>{
        console.log(val.abbreviation)
    }) */


    // Map all Data into the list Items
    const filterList = searchResults.map((val, index) => {
        let primaryName = val.name

        // This is an ugly hack because of dirty Database
        if (val.abbreviation !== "" && val.abbreviation !== "test" && val.abbreviation !== "abb" && val.abbreviation !== "abbreviation") primaryName = primaryName + " (" + val.abbreviation + ")"

        val.relations = 0;
        if (linkArray) {
            val.relations = linkArray.filter(link => (link.toId === val._id || link.fromId === val._id)).length
        }
        const key = `listItems-${index}`;

        // Function Click on Item
        const itemClichFunction = (() => {
            setTermItem(val);
            setDawerState(true);
            updateSelected(index)
            //console.log("Item was Selected:")
            //console.log("name: " + val.name)
        })
        //() => { setTermItem(val); setDawerState(true); updateSelected(index) }

        let desc = val.description
        if (val.description.slice(0, 3) === "<p>") {
            desc = val.description.slice(3, val.description.indexOf("</p>"))
        }

        return (
            <ListItem className={classes.listItem} button onClick={itemClichFunction} key={key} selected={itemSelected === index}>
                {showType && (
                    <TypeIcons type={val.type} />
                )}

                {!showType && (
                    <ListItemText
                        className={classes.listTextItem}
                        primary={primaryName}
                    />
                )}

                {showType && (
                    <ListItemText
                        className={classes.listTextItem}
                        primary={primaryName}
                        secondary={desc.substring(0, 50).concat(' ...')}
                    /* secondary={parse(val.description.substring(0, 50).concat(' ...'))} */
                    />
                )}
                <span className={classes.listTextItem} >{val.relations > 0 && val.relations}</span>
            </ListItem>
        )
    });


    // Update Link Array (This ins possibl needed because of linkArray.map)
    const [tmpLinkArray, setTmpLinkArray] = useState(linkArray);
    useEffect(() => {
        setLinkArray(tmpLinkArray)
    }, [tmpLinkArray, setLinkArray]);


    const handelReloadContent = () => {
        setReloadContetnt(Math.random)
    }

    // Generate Links
     const updateLinkHandle = () => {
         // Make a Array with all TermIDs  
         let termIDmap = []
         //console.log(termArray)
         termArray.map((term, index) => {
             termIDmap[index] = term._id
         })
 
         // Generate Links 
         const relations = generateRelations(termArray);
         // Remove Auto Relatins if thay are deleted in the Termdescription
         linkArray.map(link => {
             if (link.description === "autoLink") {
                 // Check if the Link fitting to the preseted Terms (Needed for different Domain Spaces)
                 if (termIDmap.includes(link.fromId) || termIDmap.includes(link.toId)) {
                     // Check if Autolinks are still relevant.
                     const existingRel = relations.find(obj => (link.fromId === obj.source && link.toId === obj.target));
                     if (!existingRel) {
                         DeleteLink(link._id, setTmpLinkArray, tmpLinkArray, contextType.token)
                     }
                 }
             }
             return link;
         })
         // Include Relations to database
         IncludeRelationsToDatabase(relations, linkArray, setLinkArray, contextType.token)
     };

    // Return
    return (
        <Container className={classes.container}>
            <Paper className={classes.paper}>
                <Grid container spacing={1}>

                    <div style={{ width: '100%' }}>
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        >
                            <Box flexGrow={1} >
                                {/* <TextField
                                        size="small"
                                        spacing={2}
                                        id="outlined-basic"
                                        label="Search.."
                                        variant="outlined"
                                        type="text"
                                        value={searchTerm}
                                        onChange={handleChange}
                                    />
 */}

                                <FormControl variant="outlined" size="small">
                                    <InputLabel htmlFor="outlined-adornment-password">Search..</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type='text'
                                        value={searchTerm}
                                        onChange={handleChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                {searchTerm &&
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleResetSearch}
                                                        onMouseDown={handleResetSearch}
                                                        edge="end"
                                                        size="small"
                                                    >
                                                        <ClearIcon />
                                                    </IconButton>
                                                }
                                            </InputAdornment>
                                        }
                                        labelWidth={70}
                                    />
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControlLabel
                                    className={classes.checkbox}
                                    control={
                                        <Checkbox
                                            checked={showType}
                                            onChange={handleShowType}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    }
                                    label="Details"
                                />
                            </Box>
                            {contextType.permission !== "read" && (
                                <Box>
                                    <Link to="/edit" className={classes.link}>
                                        <Tooltip title={"Add new term"}>
                                            <Fab
                                                className={classes.fab}
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                aria-label="more"
                                                size="small" ><AddIcon />
                                            </Fab>
                                        </Tooltip>
                                    </Link>
                                </Box>
                            )}
                            {contextType.permission !== "read" && (
                                <Box>
                                    <Tooltip title={"Reload from database"}>
                                        <Fab
                                            className={classes.fab}
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            aria-label="more"
                                            size="small"
                                            onClick={handelReloadContent}><RefreshIcon />
                                        </Fab>
                                    </Tooltip>
                                </Box>
                            )}
                        </Box>
                    </div>

                </Grid>
                <Divider className={classes.dividerTop} />
                <Box className={classes.list}>
                    <List>{filterList}</List>
                </Box>
                <Divider className={classes.dividerBottom} />
            </Paper>
        </Container >
    );
}

