import React from 'react';
import clsx from 'clsx';
import { TextField } from '@material-ui/core';


export const DescritionInput = ({data,onChange,classes}) => {
  

    return(
        <React.Fragment>           
            <TextField 
                name = "description"
                multiline
                rows={8}
                value={data.description}
                onChange={ event => onChange(event,data.description) }
                id="standard-basic" label="Description" 
                fullWidth 
                className={clsx (classes.margin)}
                variant="outlined"
                size="small"
            />
        </React.Fragment>
    );
}
