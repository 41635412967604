import { useState, Fragment, useContext } from 'react';


// Import Material 
import { Button } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

//Import Components
import { UpdateUser } from '../../graphqlCommunication/AuthInterface';

import AuthContext from '../../context/authContext';
import { SelectPermission } from './SelectPermission';

export const UpdateUserDialog = ({ user, handleClose, render,classes }) => {

    // Context
    const contextType = useContext(AuthContext);

    const [open, setOpen] = useState(false);
    const [values, setValues] = useState({
        email: user.email,
        permission: user.permission
    });

    const handleCancel = () => {
        setOpen(false);
    };

    const handleSave = () => {
        setOpen(false);
        handleClose();
        // Save data 
        if (values.email !== undefined) {
            user.email = values.email;

        }
        if (values.permission !== undefined) {
            user.permission = values.permission;
        }

        // Cummunication with Backend
        const userId = user._id;
        const email = user.email;
        const password = user.password;
        const permission = user.permission;

        UpdateUser(userId, email, password, permission, contextType)
    };

    const handleUpdate = () => {
        setOpen(true);
        setValues({
            email: user.email,
            permission: user.permission
        })
    };

    const handleChange = (event, newValue) => {
        let { value, name } = event.target;
        setValues(values => {
            return {
                ...values,
                [name]: value
            };
        });
    };

    return (
        <Fragment>
            {render(handleUpdate)}
            <Dialog open={open} onClose={handleCancel} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth="true">
                <DialogTitle id="form-dialog-title">Edit User</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        id="email"
                        label="E-Mail"
                        fullWidth
                        defaultValue={user.email}
                        name="email"
                        variant="outlined"
                        value={values.email}
                        onChange={event => handleChange(event, values.email)}
                        size="small"
                    />
                </DialogContent>
                <DialogContent>
                    <SelectPermission data={values} onChange={handleChange} classes={classes} />
                </DialogContent>
                <DialogContent>
                    <DialogContentText>
                        {"User Id: " + user._id}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}