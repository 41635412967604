import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { Checkbox, Divider, FormControlLabel, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    text: {
        color: theme.palette.text.disabled,
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}));

export default function SelectTermDomainSpaceSB({ data, setData, domainSpaceDefinition }) {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [selection, setSelection] = React.useState(domainSpaceDefinition);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    let domainSpaceDefinitionArray = []
    Object.keys(selection).map((dom) => {
        domainSpaceDefinitionArray = domainSpaceDefinitionArray.concat(dom);

    })

    const handleClick = () => {
        console.info(`You clicked ${domainSpaceDefinitionArray[selectedIndex]}`);
    };

    // Handle On cklick on the Checkbox
    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        if (index == 0) {
            Object.keys(selection).map((option) => {
                selection[option] = event.target.checked
            })
        }
        setSelection({ ...selection, [event.target.name]: event.target.checked });
        setOpen(true);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        // Create the Domainspace Map
        let selectedDomains = []
        Object.keys(selection).map((dom) => {
            if (selection[dom]) {
                selectedDomains = selectedDomains.concat(dom);
            }

        })
        //console.log("selectedDomains")
        //console.log(selectedDomains)
        setData(data => {
            return {
                ...data,
                ["domain"]: selectedDomains
            };
        });

        //console.log("data")
        //console.log(data)

        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    // Create Menu Items
    const menuItems = Object.keys(selection).map((option, index) => {
        //console.log(option, domainSpaceDefinition[option], index);
        return (
            <>
                <MenuItem
                    key={option + "_" + index}
                    //disabled={index === 99}
                    selected={index === selectedIndex}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                name={option}
                                color="primary"
                                checked={selection[option]}
                                onClick={(event) => handleMenuItemClick(event, index)}
                            />
                        }
                        label={option}
                    />
                </MenuItem>
                {index === 0 && (
                    <Divider className={classes.divider} />
                )}
            </>
        )

    });


    return (
        <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
                <ButtonGroup variant="contained" color="white" ref={anchorRef} aria-label="split button">
                    <Button onClick={handleClick}>{domainSpaceDefinitionArray[selectedIndex]}</Button>
                    <Button
                        color="white"
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        <Typography className={classes.text} variant="body1">Domain Space</Typography>
                                        <Divider className={classes.divider} />
                                        <>{menuItems}</>

                                        {/* 
                                        
                                        <Divider></Divider>
                                        <Button
                                            color="primary"
                                            onClick={handleToggle}
                                        >
                                            New
                                        </Button>
                                        */}

                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
}