import { Fragment, useContext, useEffect } from 'react';
import parse from 'html-react-parser'

// Import Material 
import { Badge, Box, Button, Checkbox, FormControlLabel, Grid, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles, Paper, Tooltip, Typography } from '@material-ui/core'
import { Add } from '@material-ui/icons';
//import LinkIcon from '@material-ui/icons/Link';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
//import RotateRightIcon from '@material-ui/icons/RotateRight';

import DataContext from '../../../context/dataContext';
import AuthContext from '../../../context/authContext';
import { SelectLinkType } from '../../basicComponents/SelectLinkType';
import { NameInput } from '../../addNewView/NameInput';
import { AutocompleteTerms } from '../../basicComponents/AutocompleteTerms';
import { useState } from 'react';
import { CreateLink } from '../../../graphqlCommunication/LinksInterface';
import { EditLinkDialog } from './EditLinkDialog';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';


// Style
const useStyles = makeStyles((theme) => ({
    list: {
        maxHeight: `calc(200px - ${theme.spacing(0)}px)`,
        overflow: 'auto',
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
    },
    listItem: {
        color: theme.palette.primary.light,
    },
    dividerTop: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
    },
    dividerBottom: {
        marginTop: theme.spacing(0),
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
    },
    dividerMiddle: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    text: {
        marginTop: theme.spacing(2),
        color: theme.palette.text.light,
    },
    button: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        minWidth: '200px',
    },
    icon: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(0),
        color: theme.palette.text.main,
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.text.primary
    },
    paper: {
        borderRadius: 4,
        backgroundColor: 'white',
        borderColor: 'lightGray',
        padding: theme.spacing(0),
        marginBottom: theme.spacing(1),
    },
    input: {
        minWidth: 160,
        maxWidth: 160,
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
    }
}));


export const DefinedLinks = ({ linkTypeMap }) => {

    const classes = useStyles();

    // Use Context
    const contextType = useContext(AuthContext);
    const dataContext = useContext(DataContext);
    const setLinkArray = dataContext.setLinkArray;
    const setLinkItem = dataContext.setLinkItem;
    const setTermItem = dataContext.setTermItem;
    //const setLinkItem = dataContext.setLinkItem;
    const linkArray = dataContext.linkArray;
    const termArray = dataContext.termArray;
    const termItem = dataContext.termItem;
    const linkItem = dataContext.linkItem;



    // Use State
    const [toTerm, setToTerm] = useState({});
    const [selectedLink, setSelectedLink] = useState(linkItem);
    const [secondary, setSecondary] = useState(true);
    //const [linkList, setLinkList] = useState();

    // States and Handler for the Edit Dialog
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
    };

    const handleEditDialogOpen = () => {
        setEditDialogOpen(true);
    };

    // Definititon of the new Link
    const [newLinkItem, setNewLinkItem] = useState({
        type: "",
        toId: "",
        fromId: "",
        name: "",
        abbreviation: "",
        date: "",
        description: "",
    });

    // If linkItem will change the values will be set again
    useEffect(() => {
        setNewLinkItem(linkItem)
    }, [linkItem]);



    //console.log("Arrays@DefineLinks")
    //console.log(linkArray)
    //console.log(termArray) 


    if (linkArray !== null && linkArray !== undefined) {

        // Create Link List Items
        const linkList = linkArray.map((val, index) => {
            const key = `listItems-${index}`;

            if (termItem._id === val.toId || termItem._id === val.fromId) {
                // Find Term by ID
                const toTermTemp = termArray.find(term => term._id === val.toId);
                const fromTermTemp = termArray.find(term => term._id === val.fromId);

                if (fromTermTemp && toTermTemp) {

                    // Slection of the Icon based on the Link type
                    const linkType = () => {
                        if (termItem._id === val.fromId) {
                            if (val.description === "autoLink") {
                                return (
                                    <Tooltip title="Automated Link" aria-label="add">
                                        <Badge color="primary" variant="dot">
                                            <ArrowForward color="primary" />
                                        </Badge>
                                    </Tooltip>
                                )
                            } else {
                                return (
                                    <Tooltip title={"Defined Link"} aria-label="add">
                                        <ArrowForward color="primary" />
                                    </Tooltip>
                                )
                            }
                        } else {
                            if (val.description === "autoLink") {
                                return (
                                    <Tooltip title="Automated Link" aria-label="add">
                                        <Badge color="primary" variant="dot">
                                            <ArrowBack color="primary" />
                                        </Badge>
                                    </Tooltip>
                                )
                            } else {
                                return (
                                    <Tooltip title={"Defined Link"} aria-label="add">
                                        <ArrowBack color="primary" />
                                    </Tooltip>
                                )
                            }
                        }
                    }


                    // Generate the Text
                    const primaryLinkText = () => {
                        if (termItem._id === val.fromId) {
                            return parse('<b>' + toTermTemp.name + '</b>')
                        } else {
                            return parse('<b>' + fromTermTemp.name + '</b>')
                        }
                    }
                    const secondaryLinkText = () => {
                        if (termItem._id === val.fromId) {
                            return parse(fromTermTemp.name + " - <i>" + val.type + "</i> - <b>" + toTermTemp.name + '</b>')
                        } else {
                            return parse('<b>' + fromTermTemp.name + "</b> - <i>" + val.type + "</i> - " + toTermTemp.name)
                        }
                    }

                    // List Button when click on the list Item 
                    const listButtenHandler = () => {
                        if (termItem._id === val.fromId) {
                            setTermItem(toTermTemp)
                            return
                        } else {
                            setTermItem(fromTermTemp)
                            return
                        }
                    }

                    return (
                        <ListItem button onClick={() => { listButtenHandler() }}>
                            <ListItemIcon key={key} > {linkType()} </ListItemIcon>
                            <ListItemText
                                className={classes.listItem}
                                primary={primaryLinkText()}
                                secondary={secondary ? secondaryLinkText() : null}
                            />
                            {contextType.permission !== "read" && (
                                <ListItemSecondaryAction>

                                    <IconButton button onClick={() => { setSelectedLink(val); handleEditDialogOpen() }} key={key} edge="end" aria-label="delete">
                                        <MoreHorizIcon
                                            className={classes.fab}
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            aria-label="more"
                                            size="small" >
                                        </MoreHorizIcon>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            )}
                        </ListItem>
                    )
                }
            }
            return (<></>);
        })

        const handleChange = (event, newValue) => {
            let { value, name } = event.target;
            setNewLinkItem(values => {
                return {
                    ...values,
                    [name]: value
                };
            });
        };

        // Add new Link Function
        const addNewLinkHandle = () => {

            // Make automated an describtion 
            const toTermTemp = termArray.find(term => term._id === toTerm._id);
            const fromTermTemp = termArray.find(term => term._id === termItem._id);
            newLinkItem.description = fromTermTemp.name + " " + newLinkItem.type + " " + toTermTemp.name

            newLinkItem.fromId = termItem._id
            newLinkItem.toId = toTerm._id
            newLinkItem.name = newLinkItem.type

            const d = new Date();
            let currDate = d.toISOString();
            newLinkItem.date = currDate

            //console.log("Create new Link:")
            //console.log(termItem.name + " - " + newLinkItem.type + " - " + toTerm.name)
            CreateLink(newLinkItem, setLinkArray, contextType.token)
        };

        // Delete a Link
        /*  const handleDeleteLink = () => {
             // Delete Link
             DeleteLink(selectedLink._id, setLinkArray, linkArray, contextType.token)
             //console.log("DeleteLink wit Id:" + selectedLink._id + " - " + selectedLink.name)
         }; */

        return (
            <Fragment>
                <Grid container >

                    <Grid item sm={12}>
                        <Typography className={classes.text} > {termItem.name} has the following links to </Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={secondary}
                                    onChange={(event) => setSecondary(event.target.checked)}
                                />
                            }
                            label="Show Details"
                        />
                    </Grid>

                    <Grid item sm={12}>
                        <Paper className={classes.paper} elevation={0} variant='outlined'>
                            <Box className={classes.list}>
                                <List dense={true}>{linkList}</List>
                            </Box>
                        </Paper>
                    </Grid>

                    {contextType.permission !== "read" && (
                        <>
                            <Grid item sm={12}>
                                <Typography className={classes.text} > Create new Link </Typography>
                            </Grid>

                            <Grid item sm={12}>
                                <Paper className={classes.paper} elevation={0} variant='outlined'>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="flex-end"
                                        flexWrap="wrap">

                                        <NameInput data={termItem} onChange={handleChange} classes={classes} />
                                        <SelectLinkType
                                            data={newLinkItem}
                                            onChange={handleChange}
                                            classes={classes}
                                            linkTypeMap={linkTypeMap}
                                        />
                                        <AutocompleteTerms setTerm={setToTerm} classes={classes} />

                                        <Button display="flex" variant="outlined" color="primary" onClick={addNewLinkHandle} className={classes.button} startIcon={<Add className={classes.icon} />}>Add new Link</Button>
                                    </Box>
                                </Paper>
                            </Grid>
                        </>
                    )}
                </Grid>
                <EditLinkDialog
                    linkItem={selectedLink}
                    handleEditDialogClose={handleEditDialogClose}
                    editDialogOpen={editDialogOpen}
                    linkTypeMap={linkTypeMap}
                    setLinkArray={setLinkArray}
                    setLinkItem={setLinkItem}
                    linkArray={linkArray}
                    termArray={termArray}

                ></EditLinkDialog>
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                No links there
            </Fragment>
        );
    }
}