import { Button, Divider, makeStyles, Tooltip, Typography, withStyles, Zoom } from '@material-ui/core';
import { useCallback, Fragment } from 'react';
import { useStore, getBezierPath } from 'react-flow-renderer';
import { getEdgeParams } from './utils.jsx';
import parse from 'html-react-parser'

// Syles
const useStyles = makeStyles((theme) => ({
  textField: {
    color: theme.palette.secondary.contrastText,
    background: theme.palette.secondary.main,
    marginLeft: theme.spacing(1),
    fontSize: "1.5em",
  },
  dividerTop: {
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
  },
  toolTipHeader: {
    color: theme.palette.primary.light,
  },
  toolTipBody: {
    color: theme.palette.text.primary,
  }
}));


// Tool Tip 
const HtmlTooltip = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.myDivider.main
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    maxWidth: 220,

    boxShadow: theme.shadows[1],
    fontSize: 20,
    border: "0px solid " + theme.palette.myDivider.main
  }
}));

function BootstrapHtmlTooltip(props) {
  const classes = HtmlTooltip();

  return <Tooltip arrow classes={classes} {...props} />;
}


// Main Function 
export const FloatingEdge = ({ id, source, target, markerEnd, style, labelStyle, label, idOfCenter2 }) => {

  const classes = useStyles();

  const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
  const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);

  const labelAndDesc = label.split("___")

  let edgeLabel = labelAndDesc[0]
  let description = "no Description"
  if (labelAndDesc.length > 0) description = labelAndDesc[labelAndDesc.length - 1]


  // Check if the discription is not empty 
  if(description!==""){
    edgeLabel = parse(edgeLabel + " &hellip;") // Add an Dot and parse to HTML  (&vellip; &bull;)
  }

  //console.log({ sx, sy, tx, ty, targetPos,sourcePos, label })

  /*   const [edgePath] = getBezierPath({
      sourceX: sx,
      sourceY: sy,
      sourcePosition: sourcePos,
      targetPosition: targetPos,
      targetX: tx,
      targetY: ty,
    }); */


  /////////////////////
  // Make BezierPath //
  // https://www.mediaevent.de/tutorial/svg-bezier.html

  /*  
  Startpunkt         Endpunkt
  |                 |
  +---------+      +-------------+
  |         |      |             |

  Mx  y   Cx   y,     x   y,     x   y 
  |  |   |    |      |   |      |   |
  +--+   +----+      +---+      +---+
  |       |            |        |
  |       |            |        +-- Endpunkt der Kurve
  |       |            +--Control
  |       +-- Control     des Endpunkts
  |           des Startpunkts
  |
  +-- Startpunkt der Kurve 
  */


  // Control point distance
  const cpDistTarg = 100
  const cpDistSour = 30

  // Text Orientation
  let textOrientation = "left"
  const width = -(sx - tx)
  const hight = -(sy - ty)

  //console.log("w " + width + " h" + hight)
  if (width > 0) textOrientation = "left"
  if (width <= 0) textOrientation = "right"

  // Target 
  let targetPoint = " " + tx + " " + ty
  let targetControlPoint = ""

  if (targetPos === "bottom") targetControlPoint = " " + (tx + 0) + " " + (ty + cpDistTarg)
  if (targetPos === "top") targetControlPoint = " " + (tx + 0) + " " + (ty - cpDistTarg)
  if (targetPos === "left") targetControlPoint = " " + (tx - cpDistTarg) + " " + (ty + 0)
  if (targetPos === "right") targetControlPoint = " " + (tx + cpDistTarg) + " " + (ty + 0)

  // Source
  let sourcePoint = "M" + sx + " " + sy
  let sourceControPoint = ""

  if (sourcePos === "bottom") sourceControPoint = " C" + (sx + 0) + " " + (sy + cpDistSour)
  if (sourcePos === "top") sourceControPoint = " C" + (sx + 0) + " " + (sy - cpDistSour)
  if (sourcePos === "left") sourceControPoint = " C" + (sx - cpDistSour) + " " + (sy + 0)
  if (sourcePos === "right") sourceControPoint = " C" + (sx + cpDistSour) + " " + (sy + 0)

  // Combine the edge Path
  const edgePath = sourcePoint + sourceControPoint + targetControlPoint + targetPoint

  //Line Settings 
  let strokeDasharray = "0"
  const spId = id.split("_")
  const idOfCenter = spId[spId.length - 1]
  if (idOfCenter !== source && idOfCenter !== target) strokeDasharray = "5,5"

  // Textposition (Top of the line or unter the line)
  // This is needed when you have a double link in both direction. The Line Text schould be not overlapping.
  const deltaTop = -5
  const deltaUnder = 20
  let lableDy = -5 // Lable Delta y Position
  if (targetPos === "left" && sourcePos === "right" || targetPos === "right" && sourcePos === "left") {
    if(width >0 && hight>0){
      lableDy = deltaUnder
    }
    if(width <=0 && hight>0){
      lableDy = deltaUnder
    }
    if(width <=0 && hight<=0){
      lableDy = deltaTop
    }
    if(width >0 && hight<=0){
      lableDy = deltaTop
    }
  }

  if (targetPos === "top" && sourcePos === "bottom" || targetPos === "bottom" && sourcePos === "top") {
    if(width >0 && hight>0){
      lableDy = deltaTop
    }
    if(width <=0 && hight>0){
      lableDy = deltaTop
    }
    if(width <=0 && hight<=0){
      lableDy = deltaUnder
    }
    if(width >0 && hight<=0){
      lableDy = deltaUnder
    }
  }
  


  //////////////////////////
  //      Smoothstep      //
  //////////////////////////


  let radius = 20
  const ratio1 = 0.05

  if (Math.abs(width) <= (2 * radius)) {
    radius = Math.abs(width) / 2
  }


  if (Math.abs(hight) <= (2 * radius)) {
    radius = Math.abs(hight) / 2
  }

  let orientaton = "v"

  // Source
  sourcePoint = "m" + sx + "," + sy
  let sourceLine = ""
  let sourceRounding = ""

  // Middle 
  let middleLine = ""

  // Target 
  let targetLine = ""
  let targetRounding = ""

  // Source line with one corner
  const sl_TB = "v" + (Math.sign(hight) * (Math.abs(hight) - radius))
  const sl_LR = "h" + (Math.sign(width) * (Math.abs(width) - radius))
  // Source line with two corners
  const sl_TB_2 = "v" + (Math.sign(hight) * (Math.abs(hight) / (1 / ratio1) - radius))
  const sl_LR_2 = "h" + (Math.sign(width) * (Math.abs(width) / (1 / ratio1) - radius))

  // Target line with one corner
  const tl_LR = "h" + (Math.sign(width) * (Math.abs(width) - radius))
  const tl_TB = "v" + (Math.sign(hight) * (Math.abs(hight) - radius))
  // Target line with two corners
  const tl_LR_2 = "h" + (Math.sign(width) * (Math.abs(width) / (1 / (1 - ratio1)) - radius))
  const tl_TB_2 = "v" + (Math.sign(hight) * (Math.abs(hight) / (1 / (1 - ratio1)) - radius))

  // Middle line
  const ml_H = "h" + (width - radius)
  const ml_V = "v" + (hight - radius)

  // Rounding (quater cycle) in Quadrant/sector (QI - QIV) of Koordinate system
  const r1 = "a" + radius + "," + radius + " 0 0 1 " + (radius) + "," + (radius)
  const r2 = "a" + radius + "," + radius + " 0 0 0 " + (-radius) + "," + (radius)
  const r3 = "a" + radius + "," + radius + " 0 0 0 " + (radius) + "," + (radius)
  const r4 = "a" + radius + "," + radius + " 0 0 1 " + (-radius) + "," + (radius)

  const r1_i = "a" + (radius) + "," + (radius) + " 0 0 0 " + (-radius) + "," + (-radius)
  const r2_i = "a" + (radius) + "," + (radius) + " 0 0 1 " + (radius) + "," + (-radius)
  const r3_i = "a" + (radius) + "," + (radius) + " 0 0 1 " + (-radius) + "," + (-radius)
  const r4_i = "a" + (radius) + "," + (radius) + " 0 0 0 " + (radius) + "," + (-radius)

  // Target Top
  if (targetPos === "top" && sourcePos === "bottom") {//2    
    // Source
    sourceLine = sl_TB_2
    sourceRounding = r3
    if (width < 0) sourceRounding = r4
    // Middle Line 
    middleLine = ml_H
    // Target
    targetLine = tl_TB_2
    targetRounding = r1
    if (width < 0) targetRounding = r2

    orientaton = "v"
  }

  if (targetPos === "top" && sourcePos === "left") {
    // Source
    sourceLine = sl_LR
    sourceRounding = r2
    // Target
    targetLine = tl_TB
  }

  if (targetPos === "top" && sourcePos === "right") {
    // Source
    sourceLine = sl_LR
    sourceRounding = r1
    // Target
    targetLine = tl_TB
  }

  // Target bottom
  if (targetPos === "bottom" && sourcePos === "top") {//2
    // Source
    sourceLine = sl_TB_2
    sourceRounding = r2_i
    if (width < 0) sourceRounding = r1_i
    // Middle Line 
    middleLine = ml_H
    // Target
    targetLine = tl_TB_2
    targetRounding = r4_i
    if (width < 0) targetRounding = r3_i

    orientaton = "v"
  }

  if (targetPos === "bottom" && sourcePos === "left") {
    // Source
    sourceLine = sl_LR
    sourceRounding = r3_i
    // Target
    targetLine = tl_TB
  }

  if (targetPos === "bottom" && sourcePos === "right") {
    // Source
    sourceLine = sl_LR
    sourceRounding = r4_i
    // Target
    targetLine = tl_TB
  }

  // Target Left
  if (targetPos === "left" && sourcePos === "top") {
    // Source
    sourceLine = sl_TB
    sourceRounding = r2_i
    // Target
    targetLine = tl_LR

  }

  if (targetPos === "left" && sourcePos === "bottom") {
    // Source
    sourceLine = sl_TB
    sourceRounding = r2_i
    // Target
    targetLine = tl_LR
  }

  if (targetPos === "left" && sourcePos === "right") {//2

    // Source
    sourceLine = sl_LR_2
    sourceRounding = r1
    if (hight < 0) sourceRounding = r4_i
    // Middle Line 
    middleLine = ml_V
    // Target
    targetLine = tl_LR_2
    targetRounding = r3
    if (hight < 0) targetRounding = r2_i

    orientaton = "h"

  }

  // Target right
  if (targetPos === "right" && sourcePos === "top") {
    // Source
    sourceLine = sl_TB
    sourceRounding = r1_i
    // Target
    targetLine = tl_LR
  }

  if (targetPos === "right" && sourcePos === "bottom") {
    // Source
    sourceLine = sl_TB
    sourceRounding = r4_i
    // Target
    targetLine = tl_LR
  }

  if (targetPos === "right" && sourcePos === "left") {//2
    // Source
    sourceLine = sl_LR_2
    sourceRounding = r2
    if (hight < 0) sourceRounding = r3_i
    // Middle Line 
    middleLine = ml_V
    // Target
    targetLine = tl_LR_2
    targetRounding = r4
    if (hight < 0) targetRounding = r1_i

    orientaton = "h"
  }



  let edgePathSS = sourcePoint + " " + sourceLine + " " + sourceRounding + " " + middleLine + " " + targetRounding + " " + targetLine
  if (5 > radius) {
    if (orientaton === "h") {
      edgePathSS = sourcePoint + " h" + width
    }
    if (orientaton === "v") {
      edgePathSS = sourcePoint + " v" + hight
    }
  }

  //console.log(hight)
  //console.log(edgePathSS)

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
        style={style}
        stroke-dasharray={strokeDasharray}
      />

      <BootstrapHtmlTooltip
        leaveDelay={500}
        interactive
        TransitionComponent={Zoom}
        title={
          <Fragment>
            <Typography className={classes.toolTipHeader} variant="subtitle2">Link Description</Typography>
            {/* <Divider className={classes.dividerTop} /> */}
            <Typography className={classes.toolTipBody} variant="body2">  {description}</Typography>
            {/* <Button>Hello</Button> */}
          </Fragment>
        }
      >
        <text cursor= 'pointer' background='#555' style={labelStyle}>
          <textPath href={"#" + id}
            startOffset="50%"
            text-anchor="middle"
            side={textOrientation}
          >
            <tspan dy={lableDy}>
              {edgeLabel}
            </tspan>

          </textPath>
        </text>
      </BootstrapHtmlTooltip>


      {/*  <Tooltip title={description} arrow TransitionComponent={Zoom} >
        <text background='#555' style={labelStyle}>
          <textPath href={"#" + id}
            startOffset="50%"
            text-anchor="middle"
            side={textOrientation}
          >
            <tspan dy={lableDy}>
              {edgeLabel}
            </tspan>

          </textPath>
        </text>
      </Tooltip> */}
    </>
  );
};

