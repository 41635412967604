/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, } from 'react';
import { Grid, Box, Container, Paper, Divider, FormControlLabel, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//import Autocomplete from '@material-ui/lab/Autocomplete';
//import { ForceGraph } from './ForceGraph2D';

// Use Context
import DataContext from '../../../context/dataContext';
import { SelectGraphType } from '../../basicComponents/SelectGraphType';
import { ForceGraphD3 } from './ForceGraphD3';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    container: {
        paddingTop: theme.spacing(9),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        background: "lightgray",
        height: "100vh",
        maxWidth: "none",
    },
    paper: {
        height: `calc(100% - ${theme.spacing(2)}px)`,
        padding: theme.spacing(1),
    },
    dividerTop: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(-1),
        marginRight: theme.spacing(-1),
        marginBottom: theme.spacing(1),
    },
    autocomplete: {
        width: 300,
        [theme.breakpoints.down("sm")]: {
            width: 300,
        }
    },
    fabOrientation: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        }
    }
}));


export const ForceGraphContainer = () => {

    const classes = useStyles();

    // Use Context
    const dataContext = useContext(DataContext);
    const termArray = dataContext.termArray;
    const termItem = dataContext.termItem;
    const linkArray = dataContext.linkArray;
    const setTermItem = dataContext.setTermItem;

    const [filter, setFilter] = useState(true);

    const setGraphTypeSelection = dataContext.setGraphTypeSelection;
    const graphTypeSelection = dataContext.graphTypeSelection;

    // Select Graph Type in center view
    const handleChangeGraphType = (event, newValue) => {
        let { value} = event.target;
        setGraphTypeSelection(value)
    };
    // 
    const handleChangeFilter = (event, newValue) => {
        setFilter(event.target.checked)
        //console.log(event.target.checked)
    }

    return (
        <>
            <Container className={classes.container}>
                <Paper className={classes.paper}>
                    <div style={{ width: "100%", height: "90%" }}>
                        <Grid direction="row" spacing={1} container>
                            <Grid item xs={8}>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <SelectGraphType type={graphTypeSelection} onChange={handleChangeGraphType} classes={classes} />
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                                    <div className={classes.root}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={filter}
                                                    onChange={handleChangeFilter}
                                                    name="filter"
                                                    color="primary"
                                                />
                                            }
                                            label="Filter"
                                        />
                                       {/*  <Fab onClick={loadElements} size="small" color="primary" aria-label="edit">
                                            <AutorenewIcon />
                                        </Fab> */}
                                    </div>
                                </Box>

                            </Grid>
                        </Grid>
                        <Divider className={classes.dividerTop} />

                        <ForceGraphD3 termArray={termArray} termItem={termItem} linkArray={linkArray} setTermItem={setTermItem} filter={filter}></ForceGraphD3>

                        {/* <ForceGraph termArray={termArray} termItem={termItem} linkArray={linkArray}></ForceGraph> */}

                    </div>
                </Paper>
            </Container>
        </>
    );
};
