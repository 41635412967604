import { useState, Fragment, useContext } from 'react';
import AuthContext from '../../context/authContext';

// Import Material 
import { Button, makeStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

//Import Components
import { UpdateTerm } from '../../graphqlCommunication/TermsInterface';
import { SelectExternalLinkType } from '../basicComponents/SelectExternalLinkType';
import { CreateExternalLink } from '../../graphqlCommunication/ExternalLinksInterface';


const useStyles = makeStyles((theme) => ({
    input: {
        minWidth: 100,
        maxWidth: 180,
    },
    margin: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    }
}));

export const ExternalLinkDialog = ({ termItem, setTermItem, handleExternalLinkDialogClose, externalLinkDialogOpen, token }) => {
    const classes = useStyles();
    const contextType = useContext(AuthContext);

    const [exLiId, setExLiId] = useState("")

    const d = new Date();
    let currDate = d.toISOString();
    const externalLinkItem = {
        url: "",
        description: "",
        type: "",
        date: currDate,
    }


    //console.log(externalLinkItem)


    const [values, setValues] = useState({
        url: externalLinkItem.url,
        description: externalLinkItem.description,
        type: externalLinkItem.type
    });

    //console.log(values)


    // If termItem will change the values will be set again
    /* useEffect(() => {
        setValues({
            url: externalLinkItem.url,
            description: externalLinkItem.description,
            type: externalLinkItem.type
        })
    }, [externalLinkItem]); */

    const handleClose = () => {
        handleExternalLinkDialogClose();
    };


    const handleSave = () => {
        handleClose();
        // Save data 
        if (values.url !== undefined) {
            externalLinkItem.url = values.url;
        }
        if (values.description !== undefined) {
            externalLinkItem.description = values.description;
        }
        if (values.type !== undefined) {
            externalLinkItem.type = values.type;
        }

        const d = new Date();
        let currDate = d.toISOString();
        externalLinkItem.date = currDate;
        // Cummunication with Backend
        const res = CreateExternalLink(externalLinkItem, setExLiId,exLiId, token)

        //console.log("externalLinkItem._id")
        //console.log(exLiId)
        //console.log(res)

        termItem.externalLinks = [exLiId]
        UpdateTerm(termItem, contextType.token)
        setTermItem(termItem)// WARNING! if the Server connection went wrong the local term will change althought.
    };

    const handleChange = (event, newValue) => {
        let { value, name } = event.target;
        if (name === "check") {
            const { checked } = event.target;
            value = checked;
        }
        setValues(values => {
            //console.log(values)
            return {
                ...values,
                [name]: value
            };
        });
    };

    return (
        <Fragment>
            <Dialog open={externalLinkDialogOpen} onClose={handleExternalLinkDialogClose} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth="true">
                <DialogTitle id="form-dialog-title">Add Picture</DialogTitle>
                <DialogContent>
                    <TextField
                        id="url"
                        label="Url"
                        fullWidth
                        defaultValue={externalLinkItem.url}
                        name="url"
                        variant="outlined"
                        value={values.url}
                        onChange={event => handleChange(event, values.url)}
                    />
                    <SelectExternalLinkType data={values} onChange={handleChange} classes={classes} />
                    <TextField

                        margin="dense"
                        id="description"
                        label="description"
                        fullWidth
                        defaultValue={externalLinkItem.description}
                        multiline
                        rows={5}
                        name="description"
                        variant="outlined"
                        value={values.description}
                        onChange={event => handleChange(event, values.description)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}