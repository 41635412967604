import { useState, Fragment } from 'react';
import clsx from 'clsx';


// Import Material 
import { Button, FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
//import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
//import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';



const useStyles = makeStyles((theme) => ({
    input: {
        minWidth: 100,
        maxWidth: 180,
    },
    margin: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    }
}));

export const SettingDialog = ({ setGraphLayoutType,graphLayoutType, handleEditDialogClose, editDialogOpen }) => {
    const classes = useStyles();


    const handleClose = () => {
        handleEditDialogClose();
    };


    const handleSave = () => {
        setGraphLayoutType(values)
        handleClose();
    };

    const [values, setValues] = useState(graphLayoutType);

    const handleChange = (event, newValue) => {
        let value= event.target;
        //console.log(value.value)
        setValues(value.value)
    };

    return (
        <Fragment>
            <Dialog open={editDialogOpen} onClose={handleEditDialogClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth="true">
                <DialogTitle id="form-dialog-title">Grap Settings</DialogTitle>
                <FormControl variant="outlined" className={clsx (classes.input, classes.margin)} >
                    <InputLabel id="simple-select-label">Layout Type</InputLabel>
                    <Select
                        name="Layout Type"
                        labelId="simple-select-label"
                        id="simple-select"
                        value={values.name}
                        onChange={event => handleChange(event, values.name)}
                        label="Layout Type"
                    >
                        <MenuItem value="cycle">Cycle</MenuItem>
                        <MenuItem value="hierarchy">Hierarchy</MenuItem>
                    </Select>
                </FormControl>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}