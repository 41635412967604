
//////////////////////////////////////
// Functions for Filtering the data //
//////////////////////////////////////

import { generateRelations } from "../visualisation/generateRelations";

export function generateIdMap(relations, id) {
    // Make a Map with all Ids wihich have an relation to the main object
    const idMap = new Map();
    relations.map(obj => {
        if (obj.source === id || obj.target === id) {
            idMap.set(obj.source, "id")
            idMap.set(obj.target, "id")
        }
        return idMap;
    });
    return idMap;
}

export function generateMultiIdMap(relations, ids) {
    // Make a Map with all Ids wihich have an relation to the main object
    const idMap = new Map();
    relations.map(obj => {
        ids.map(id => {
            if (obj.source === id || obj.target === id) {
                idMap.set(obj.source, "id")
                idMap.set(obj.target, "id")
            }
            return idMap;
        })
    });
    return idMap;
}

export function generateIdMapSource(relations, id) {
    // Make a Map with all Ids wihich have an relation to the main object
    const idMapSource = new Map();
    relations.map(obj => {
        if (obj.source === id || obj.target === id) {
            idMapSource.set(obj.source, "id")
        }
        return idMapSource;
    });
    return idMapSource;
}

export function generateIdMapTarget(relations, id) {
    // Make a Map with all Ids wihich have an relation to the main object
    const idMapTarget = new Map();
    relations.map(obj => {
        if (obj.source === id || obj.target === id) {
            idMapTarget.set(obj.target, "id")
        }
        return idMapTarget;
    });
    return idMapTarget;
}

export function generateTermMap(termArray, idMap, id) {
    // Make a Map with all Terms wihich have an relation to the main object
    const termMap = new Map();
    termArray.map(obj => {
        if (idMap.has(obj._id) && obj._id !== id) {
            termMap.set(obj.name, "id")
        }
        return termMap;
    });
    return termMap;
}

export function generateTermArray(termArray, idMap, id, includeMainTerm) {
    // Make a Map with all Terms wihich have an relation to the main object
    const filteredTermArray = [];
    termArray.map(obj => {
        if (includeMainTerm) {
            if (idMap.has(obj._id)) {
                filteredTermArray.push(obj)
            }
        } else {
            if (idMap.has(obj._id) && obj._id !== id) {
                filteredTermArray.push(obj)
            }
        }
        return filteredTermArray;
    });
    return filteredTermArray;
}

export function generateFilteredRelations(relations, idMap) {
    // Make a Map with all Terms wihich have an relation to the main object
    const filteredRelations = [];
    relations.map(obj => {
        if (idMap.has(obj.source) && idMap.has(obj.target)) {
            filteredRelations.push(obj)
        }
        return filteredRelations
    });
    return filteredRelations;
}

///////////////////
// Generate HTML //
///////////////////

// Generate a HTML text for highlighting terms
export function generateHtmlText(myText, termMap) {
    var newText = myText.description;
    termMap.forEach(function (value, term) {
        // Check Upper and Lower Case
        let pos = newText.search(term);
        let posLC = newText.search(term.toLowerCase());
        if (pos === -1 && posLC !== 0) {
            newText = newText.replace(term.toLowerCase(), '<b>' + term.toLowerCase() + '</b>');
        }
        newText = newText.replace(term, '<b>' + term + '</b>');
    })
    return newText;
}

// Add the Html description to the termItem Array
export function addHtmlToTermItem(setTermItem, val, termArray) {
    //console.log("--- addHtmlToTermItem ---")
    const id = val._id;
    const relations = generateRelations(termArray);
    const idMap = generateIdMap(relations, id)
    const termMap = generateTermMap(termArray, idMap, id)
    val.descriptionHtml = generateHtmlText(val, termMap)
    setTermItem(val)
}