import { useState, Fragment, useContext } from 'react';

// Import Material 
import { Button } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

//Import Components

export const RemeoveSettingDialog = ({settingName,rowParas, handleButtonRemove, handleClose, render}) => {

    const [open, setOpen] = useState(false);

    const handleUpdate = () => {
        setOpen(true);
    };

    const handleRemove= () => {
        handleButtonRemove(rowParas[0],rowParas[1],rowParas[2],rowParas[3])
        setOpen(false);
    };


    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            {render(handleUpdate)}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth="true">
                <DialogTitle id="form-dialog-title">Remove {rowParas[0]}</DialogTitle>
                <DialogContent>All terms will keep this parameter</DialogContent>

                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleRemove} color="primary">
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}