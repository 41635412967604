import React from 'react';
import clsx from 'clsx';
import { Select, InputLabel, MenuItem, FormControl } from '@material-ui/core';


export const SelectPermission = ({ data, onChange, classes }) => {
    return (
        <React.Fragment>
            <FormControl size="small" variant="outlined" className={clsx(classes.input, classes.margin)} >
                <InputLabel id="simple-select-label">Permission</InputLabel>
                <Select
                    name="permission"
                    labelId="simple-select-label"
                    id="simple-select"
                    onChange={event => onChange(event, data.permission)}
                    label="Permission"
                    value={data.permission}
                    defaultValue={data.permission}
                >
                    <MenuItem value="read">Read Only</MenuItem>
                    <MenuItem value="write">Write</MenuItem>
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="inactive">Inactive</MenuItem>
                </Select>
            </FormControl>
        </React.Fragment>
    );
}