import React from 'react';
import clsx from 'clsx';
import { Select, InputLabel, MenuItem, FormControl } from '@material-ui/core';


export const SelectTermType = ({ data, onChange, classes, termTypeMap }) => {


    const items = termTypeMap.map((val, index) => {
        return (
            <MenuItem value={val}>{val}</MenuItem>
        )
    });

    return (
        <React.Fragment>
            <FormControl size="small" variant="outlined" className={clsx(classes.input, classes.margin)} >
                <InputLabel id="simple-select-label">Type</InputLabel>
                <Select
                    name="type"
                    labelId="simple-select-label"
                    id="simple-select"                
                    value={data.type}
                    onChange={event => onChange(event, data.type)}
                    label="type"
                >

                    <MenuItem aria-label="None" value="" />
                    {items}
                    
                </Select>
            </FormControl>
        </React.Fragment>
    );
}